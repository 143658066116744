import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label, Tooltip} from "reactstrap";
import BillingService from "../../../services/billing.service";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import de from 'date-fns/locale/de';
import moment from "moment";
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";
import Holidays from "date-holidays";
import WorkDayEditFrame from "./WorkDayEditFrame";
import tokenService from "../../../services/token.service";
import TokenService from "../../../services/token.service";

registerLocale('de', de)


const DayCell = (props) => {
    const ref = useRef(null)

    const [tooltipOpen, toggleTooltip] = useState(false)

    const toggle = (v) => {
        toggleTooltip(!tooltipOpen)
    }

    const md = new moment(props.day)


    let blocked = false;



    const openEditFrame = () => {
        if(blocked)
            return;
        console.log("open edit frame event")
        console.log(props.extraInfo)


        const wuEditWindow = {
            name : "Arbeitstag "+md.format('DD.MM.YYYY'),
            component: <WorkDayEditFrame
                refreshTable={props.refreshTable}
                extraInfo={props.extraInfo}
                day={props.day}
                empID={props.empID}
                possibleDayOptions={props.possibleDayOptions}
                closeWindow={props.closeWindow}
                openWindow={props.openWindow}

            />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 600,
            minHeight: 400,
            x: 600,
            y: 700
        }
        props.openWindow(wuEditWindow);
    }

    let tdClasses = "day-cell ";

    if(md.date() === 1){
        tdClasses += "day-last-of-month "
    }

    let spanClasses = "day-span ";

    let dayNum = md.locale('de').format('D');
    if(md.isSame(new Date(), "day")){
         spanClasses += "font-weight-bold text-light ";

    }

    let tooltip =   <></>

    if(props.day.holidayInfo){
        switch (props.day.holidayInfo[0].name){
            case "Rosenmontag":
            case "Faschingsdienstag":
            case "Aschermittwoch":
            case "Gründonnerstag":
            case "Muttertag":
            case "Valentinstag":
            case "Weiberfastnacht":
                break;


            case "Heiliger Abend":
            case "Silvester":
                spanClasses += "day-holiday-half-e "
                break;

            default:
                spanClasses += "day-holiday "
                blocked = true;
                break;
        }

        tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
            {props.day.holidayInfo[0].name}
        </Tooltip>

    }


    if(md.weekday() == 5){
        blocked = true;
        spanClasses += "day-saturday "
    }
    else if(md.weekday() == 6){
        blocked = true;
        spanClasses += "day-sunday "
    }

    if(tokenService.getUser().user.userId != props.empID){
        spanClasses += "day-blocked "
        blocked = true;
    }

    if(props.extraInfo){
      switch (props.extraInfo.selected_type){
          case "sickness":
              if(spanClasses.includes("day-holiday-half-e")){
                  spanClasses = spanClasses.replace("day-holiday-half-e", "day-sickness-half-s-holiday-e")
              }
              else
                  spanClasses +=  "day-sickness ";
              tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
                  {props.extraInfo.approved === 1 ? "Krankheit (bestätigt)" : "Krankheit"}
              </Tooltip>
              break;
          case "vacation":
              spanClasses += props.extraInfo.approved === 1 ? "day-vacation-accepted " : "day-vacation-request";
              tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
                  {props.extraInfo.approved === 1 ? "Urlaub (bestätigt)" : "Urlaub"}
              </Tooltip>
              break;
          case "homeoffice":
              spanClasses += "day-homeoffice";

              tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
                 Home Office
              </Tooltip>
              break;
          case "vacation-half-s":
              if(spanClasses.includes("day-holiday-half-e")){
                  spanClasses = spanClasses.replace("day-holiday-half-e", props.extraInfo.approved === 1 ? "day-vacation-accepted-half-s-holiday-e " : "day-vacation-request-half-s-holiday-e")
              }
              else
                spanClasses += props.extraInfo.approved === 1 ? "day-vacation-accepted-half-s " : "day-vacation-request-half-s";
              tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
                  {props.extraInfo.approved === 1 ? "Urlaub Halbtags (bestätigt)" : "Urlaub Halbtags"}
              </Tooltip>
              break;
          case "vacation-half-e":
              if(spanClasses.includes("day-holiday-half-s")){
                  spanClasses = spanClasses.replace("day-holiday-half-s", props.extraInfo.approved === 1 ? "day-vacation-accepted-half-e-holiday-s " : "day-vacation-request-half-e-holiday-s")
              }
              else
                spanClasses += props.extraInfo.approved === 1 ? "day-vacation-accepted-half-e " : "day-vacation-request-half-e";
              tooltip =   <Tooltip placement="right" trigger="hover" isOpen={tooltipOpen} target={ref} toggle={toggle}>
                  {props.extraInfo.approved === 1 ? "Urlaub Halbtags (bestätigt)" : "Urlaub Halbtags"}
              </Tooltip>
              break;
      }
    }
    else {


    }
    try {

    return (
        <td className={tdClasses} onClick={openEditFrame}>
            <span className={spanClasses} ref={ref} onMouseEnter={() => props.day.holidayInfo && toggleTooltip(true)}>
            {dayNum.length === 1 ? "0"+dayNum : dayNum}

                {tooltip}
        </span>
        </td>

    )

    }
    catch (e){
        return <></>
    }
};

export default DayCell;