import {useEffect, useState} from "react";
import {Buffer} from 'buffer';

import BackupCheckService from "../../../services/backup_check.service";
import Utils from "../../Utils";
import {Button, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";


const BackupReportQnapSync = (props) => {

    const [qnapSyncReport, setqnapSyncReport] = useState([])


    useEffect(() => {
        BackupCheckService.getQnapSyncReport(props.uid).then((response) => {

            setqnapSyncReport(response.data)
        })

    }, [])


    return (
        <Table className="text-white bg-dark">
            <thead>
            <tr>
                <th>Task</th>
                <th>Ergebnis</th>
                <th>Details</th>
                <th>IP-Adresse</th>
                <th>FolderPairs</th>
                <th>totalFiles</th>
                <th>totalFolder</th>
                <th>skippedFiles</th>
                <th>backedUpFiles</th>
                <th>totalFileSize</th>
                <th>averageTransmitSpeed</th>
            </tr>
            </thead>
            <tbody>
            {
                qnapSyncReport.length > 0 && qnapSyncReport.map((report, i) =>
                    <tr>
                        <th scope="row">{report.task}</th>
                        <td className={"text-"+report.color}>{report.result}</td>
                        <td>{report.details}</td>
                        <td>{report.ip}</td>
                        <td>{report.folderPairs}</td>
                        <td>{report.totalFiles}</td>
                        <td>{report.totalFolders}</td>
                        <td>{report.skippedFiles}</td>
                        <td>{report.backedUpFiles}</td>
                        <td>{report.totalFileSize}</td>
                        <td>{report.averageTransmitSpeed}</td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default BackupReportQnapSync;