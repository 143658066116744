import React, {useEffect, useRef, useState} from 'react';
import {
    Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import BillingService from "../../../services/billing.service";
import Utils from "../../Utils";
import Select from "react-select";
import Toastr from "../../notifications/Toastr";
import UserService from "../../../services/user.service";

import moment from "moment";
import 'moment/locale/de';
import DayCell from "./DayCell";
import Holidays from "date-holidays";
import TokenService from "../../../services/token.service";
import DayOffManagementRow from "./DayOffManagementRow";


const WorkDayRequests = (props) => {


    const getLeftDays = () => {
        let daysLeft = TokenService.getUser().user.vacation_day_count * (new Date().getFullYear() - 2021);//2022 Start, davon ausgehend, dass sich der Jahresurlaub nicht geändert hat.

        for (const x of daysOff){
            if(x.user_id === TokenService.getUser().user.userId){

                switch (x.selected_type){
                    case "vacation":
                        daysLeft--;
                        break;
                    case "vacation-half-e":
                    case "vacation-half-s":
                        daysLeft -= 0.5;
                        break;
                }
            }




        }
        return daysLeft;
    }
    const [actionMenuOpen, toggleActionMenu] = useState(false);

    const possibleMonths = [
        { value: 1, label: 'Januar'},
        { value: 2, label: 'Februar'},
        { value: 3, label: 'März'},
        { value: 4, label: 'April'},
        { value: 5, label: 'Mai'},
        { value: 6, label: 'Juni'},
        { value: 7, label: 'Juli'},
        { value: 8, label: 'August'},
        { value: 9, label: 'September'},
        { value: 10, label: 'Oktober'},
        { value: 11, label: 'November'},
        { value: 12, label: 'Dezember'},
    ]

    const possibleYears = [
        { value: 2021, label: '2021'},
        { value: 2022, label: '2022'},

    ]



    const [employees, setEmployees] = useState([{}]);
    const [daysOff, setDaysOff] = useState([{}]);

    const possibleDayOptions = [
        { value: '', label: 'Keine'},
        { value: 'sickness', label: 'Krankheit'},
        { value: 'vacation', label: 'Urlaub'},
        { value: 'vacation-half-s', label: 'Urlaub (Vormittag)'},
        { value: 'vacation-half-e', label: 'Urlaub (Nachmittag)'},
    ]


    const getDatesInRange= (startDate, endDate) => {
        const date = new Date (new Date(startDate.getTime()).toDateString());

        const hd = new Holidays()
        hd.init('DE', 'NI', 'NI')

        console.log(hd.getHolidays("2022"))
        const dates = [];

        while (date <= endDate) {
            let nDate = new Date(date);
            nDate.setTime(date.getTime() + 14 * 60 * 60 * 1000);
            nDate.holidayInfo = hd.isHoliday(nDate);

            console.log("while")
            console.log(nDate)
            console.log(hd.isHoliday(nDate))
            dates.push(nDate);

            date.setDate(date.getDate() + 1);


        }

        return dates;
    }


    let month = -1;
    let monthSpan = 1;
    let year = -1;
    let yearSpan = 1;
    const refreshTable = () =>{
        UserService.getUserSummary().then((response) => {



            setEmployees(response.data.filter(x => x.email.endsWith("cva.de")))


        })

        UserService.getDaysOff().then(response => {
            for(let e of response.data)
                e.day = new Date(e.day);
            setDaysOff( response.data)
            console.log(daysOff)
        })
    }

    useEffect(() => {
        refreshTable()
    }, [])
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Table className="table-dark table-striped  table-borderless table- mt-5 ">
                        <thead className="top-0 bg-darker">
                        <tr>
                            <th className=" top-0 bg-darker">Tag</th>
                            <th className=" top-0 bg-darker">Typ</th>
                            <th className=" top-0 bg-darker">Mitarbeiter</th>
                            <th className=" top-0 bg-darker text-center ">Bestätigt</th>

                        </tr>
                        </thead>
                        <tbody>


                        {
                            daysOff.filter(x => x.approved === 0).map((day, i) => {


                                return <DayOffManagementRow day={day} employees={employees} refreshTable={refreshTable}/>


                            })
                        }
                        {
                                    daysOff.filter(x => x.approved === 1).map((day, i) =>{


                                    return <DayOffManagementRow day={day} employees={employees} refreshTable={refreshTable}/>



                                    }
                                )
                            }

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
};

export default WorkDayRequests;