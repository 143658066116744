import {Component, useEffect, useRef, useState} from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button, Table, Spinner, Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faInfo } from '@fortawesome/free-solid-svg-icons';

import BackupCheckService from "../../../services/backup_check.service";
import DragAndResizeableWindow from "../../DragAndResizeableWindow";
import Utils from "../../Utils";
import UserEdit from "../UserControl/UserEdit";
import moment from "moment";
import BackupReportAccountForm from "./BackupReportAccountForm";
import BackupReportHTML from "./BackupReportHTML";
import BackupReportTrilead from "./BackupReportTrilead";
import BackupReportQnapSync from "./BackupReportQnapSync";
import Select from "react-select";

const BackupReportInbox = (props) => {

    const [inboxTable, setInboxTable] = useState([{}]);

    const showDetails = (report) =>{

        let name = "Fenstername";

            switch (report.type){
                case "qnap-sync":
                    name = `QNAP-Sync Report für UID #${report.uid} von ${report.email}`;
                    const qnapSycnReport = {
                        name,
                        component: <BackupReportQnapSync
                            uid={report.uid}
                            closeWindow={() => props.closeWindow(name)} />,
                        open: true,
                        minimized: false,
                        width: 1350,
                        minWidth: 600,
                        height: 300,
                        minHeight: 300,
                        x: 1,
                        y: 1
                    }
                    props.openWindow(qnapSycnReport);
                    break;
                case "trilead":
                    name = `Trilead Report für UID #${report.uid} von ${report.email}`;
                    const trileadReport = {
                        name,
                        component: <BackupReportTrilead
                            uid={report.uid}
                            closeWindow={() => props.closeWindow(name)} />,
                        open: true,
                        minimized: false,
                        width: 1600,
                        minWidth: 600,
                        height: 800,
                        minHeight: 400,
                        x: 1,
                        y: 1
                    }
                    props.openWindow(trileadReport);
                    break;
                case "iperius":
                case "navicat":
                case "proxmox":
                case "hlbackup":
                    name = `HTML Report für UID #${report.uid} von ${report.email}`;
                    const htmlReportWindow = {
                        name,
                        component: <BackupReportHTML
                            uid={report.uid}
                            closeWindow={() => props.closeWindow(name)} />,
                        open: true,
                        minimized: false,
                        width: 900,
                        minWidth: 600,
                        height: 600,
                        minHeight: 400,
                        x: 1,
                        y: 1
                    }
                    props.openWindow(htmlReportWindow);
                    break;
            }

        }


    const [hasLoaded, setHasLoaded] = useState(false);
    const [queryText, setQueryText] = useState("");

    const refreshInboxTable = () =>{
        setHasLoaded(false)
        BackupCheckService.getInboxSummary(queryText).then((response) => {

            for(let ba of response.data) {
                if (ba.result == 1)
                    ba.color_class = "text-success"
                else if (ba.result === 2)
                    ba.color_class = "text-warning"
                else if (ba.result === 3)
                    ba.color_class = "text-danger"
                else ba.color_class = "text-info"
            }

            setInboxTable(response.data)
            setHasLoaded(true)

        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            refreshInboxTable()
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [queryText])


    useEffect(() => {
        refreshInboxTable()
    }, [])
    return (
        <>
            <Row className={"mb-3 mx-auto"}>
                <Col xs="12">
                    <Label for="queryText">Suche</Label>

                    <Input
                        type="queryText"
                        name="queryText"
                        id="queryText"

                        onChange={(e) => {
                            setQueryText(e.target.value)
                            }
                        }
                        placeholder="Suche nach..."
                    />

                </Col>

            </Row>
        <Table className="table-dark table-striped table-hover table-borderless">
            <thead>
            <tr>
                <th className="bg-darker">UID</th>
                <th className="bg-darker">Betreff</th>
                <th className="bg-darker">Account</th>
                <th className="bg-darker">E-Mail</th>
                <th className="bg-darker">Typ</th>
                <th className="bg-darker">Eingang</th>
                <th className="bg-darker">Eingang</th>
                <th className="bg-darker">Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {
                hasLoaded ?
                inboxTable.map((report, i) =>
                    <tr>
                        <th scope="row">{report.uid}</th>
                        <td>{report.subject}</td>
                        <td>{report.name}</td>
                        <td>{report.email}</td>
                        <td>{report.type}</td>
                        <td className={"text-center"}>{Utils.formatDate(report.date)}</td>
                        <td className={"text-center " + report.color_class}>{Utils.formatBackupResult(report.result)}</td>
                        <td className={"text-center"}>
                            <Button variant="outline-dark"  size="sm" onClick={() => {
                                showDetails(report)
                            }} >
                                <FontAwesomeIcon className="pe-1 ps-1" icon={faInfo} />
                            </Button>
                        </td>
                    </tr>
                )
                    :
                    <div className="w-1000 text-center">
                    <Spinner animation="border" className="text-center" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
            </tbody>
        </Table>
        </>
    )
};

export default BackupReportInbox;