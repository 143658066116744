import {useEffect,  useState} from 'react';
import {
    Button, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import NetManagementService from "../../../services/net_management.service";
import Utils from "../../Utils";
import HostEdit from "./HostEdit";


const BackupReportInbox = (props) => {

    const [netDetailTable, setNetDetailTable] = useState([{}]);

    const toggleMonitoring = (host) =>{

        const payload = {
            id : host.id,
            monitored : !host.monitored
        }
        NetManagementService.toggleHostMonitoring(payload).then((response) => {
            loadNetDetail()
            NetManagementService.reloadAlgalonHosts().then(r => console.log("Algalon Hosts neu geladen."))
        })
    }
    const editHost = (host) =>{


        const name = `Host Detail Bearbeiten #${host.id} - ${host.address}/32`;


        const editHostWindow = {
            name,
            component: <HostEdit
                id={host.id}
                refreshTable={loadNetDetail}
                openWindow={props.openWindow}
                closeNameWindow={(c) => {
                    console.log("props")
                    console.log(props)
                    console.log("c")
                    console.log(c)

                    props.closeWindow2(c)


                }}
                closeWindow={() => props.closeWindow(name)}
                 />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 950,
            minHeight: 930,
            x: 1,
            y: 1
        }

        props.openWindow(editHostWindow);
    }

    const loadNetDetail = () =>{

        NetManagementService.getSubnetDirByID(props.id).then((response) => {
            console.log(response.data)
            setNetDetailTable(response.data)
        })
    }
    useEffect(() => {
        loadNetDetail()

    }, [])
    return (
        <Table className="table-dark table-striped table-hover table-borderless">
            <thead>
            <tr>
                <th>#</th>
                <th>Adresse</th>
                <th>Name</th>
                <th>Hostname</th>
                <th>Ping</th>
                <th>Description</th>
                <th>Monitoring</th>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {
                netDetailTable.map((host, i) =>
                    <tr>
                        <th scope="row">{host.id}</th>
                        <td>{host.address}</td>
                        <td>{host.name}</td>
                        <td>{host.hostname}</td>
                        <td>{host.ping}</td>
                        <td>{host.description}</td>
                        <td className={"text-center " + (host.monitored === 1 ? "text-success" : "text-danger" )}><a href="#toggle" onClick={() => {toggleMonitoring(host)}}>{host.monitored === 1 ? "✔" : "✘"}</a></td>
                        <td>
                            <Button variant="outline-dark" size="sm" onClick={() => {
                                editHost(host)
                            }} >
                                <FontAwesomeIcon  icon={faPencilAlt} />
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default BackupReportInbox;