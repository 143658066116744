import {Component, useEffect, useRef, useState} from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import DragAndResizeableWindow from "../../DragAndResizeableWindow";
import Utils from "../../Utils";
import UserEdit from "./UserEdit";

const UserControl = (props) => {

    const [userTable, setUserTable] = useState([{}]);
    const editUser = (user) =>{
        const userEditWindow =
                {
                    name : "Benutzer ("+user.firstName+ " "+user.lastName+") bearbeiten # "+user.id+" / "+user.username,
                    component : <UserEdit user={user}/>,
                    open : true,
                    minimized : false,
                    width: 600,
                    minWidth: 600,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                }
        props.openWindow(userEditWindow);
    }
    const refreshUserTable = () =>{
        UserService.getUserSummary().then((response) => {
            for(const user of response.data){

                console.log(user.groups)
               user.groups = JSON.parse(user.groups)
                console.log(user.groups)

                user.selectedGroups = []
                for(const group of user.groups){
                    user.selectedGroups.push(  { value: group, label: group, color: '#FF8B00' });
                }

            }

            setUserTable(response.data)



        })
    }
    useEffect(() => {
        refreshUserTable()

    }, [])
    return (
        <Table className="table-dark table-striped  table-borderless table-hover">
            <thead>
            <tr>
                <th className={"bg-darker"}>#</th>
                <th className={"bg-darker"}>Benutzername</th>
                <th className={"bg-darker"}>Vorname</th>
                <th className={"bg-darker"}>Nachname</th>
                <th className={"bg-darker"}>E-Mail</th>
                <th className={"bg-darker"}>Letzter Login</th>
                <th className={"bg-darker"}>Angelegt am</th>
                <th className={"bg-darker"}>Aktiviert</th>
                <th className={"bg-darker"}>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {
                userTable.map((user, i) =>
                    <tr className={"text-white"}>
                        <th scope="row">{user.id}</th>
                        <td>{user.username}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{Utils.formatDate(user.last_login)}</td>
                        <td>{Utils.formatDate(user.signup)}</td>
                        <td className="text-center">{user.activated == 1 ? "ja" : "nein"}</td>
                        <td>
                            <Button variant="outline-dark"  size="sm" onClick={() => {
                                editUser(user)
                            }} >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default UserControl;