import api from './api';
class BackupCheckService {
    getInboxSummary(queryText) {
        return api.get('/backup_checker/get/inbox/summary?queryText='+queryText);
    }
    getHTMLReport(uid){
        return api.get('backup_checker/get/inbox/report/html?uid='+uid);
    }

    getTrileadReport(uid){
        return api.get('backup_checker/get/inbox/report/trilead?uid='+uid);
    }

    getQnapSyncReport(uid){
        return api.get('backup_checker/get/inbox/report/qnap/sync?uid='+uid);
    }

    getAccountSummary(queryText) {
        return api.get('/backup_checker/get/account/summary?queryText='+queryText);
    }
    saveAccount(data) {
        return api.post('/backup_checker/edit/account/details', data);
    }
    addAccount(data) {
        return api.post('/backup_checker/add/account', data);
    }
}
export default new BackupCheckService();