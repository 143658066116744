import {useEffect, useRef, useState} from 'react';
import {
    Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import BillingService from "../../../services/billing.service";
import Utils from "../../Utils";
import Select from "react-select";
import WorkUnitFrame from "./WorkUnitFrame";
import Toastr from "../../notifications/Toastr";
import NetManagementService from "../../../services/net_management.service";


const WorkUnitManagement = (props) => {


    const [actionMenuOpen, toggleActionMenu] = useState(false);

    const possibleMonths = [
        { value: 1, label: 'Januar'},
        { value: 2, label: 'Februar'},
        { value: 3, label: 'März'},
        { value: 4, label: 'April'},
        { value: 5, label: 'Mai'},
        { value: 6, label: 'Juni'},
        { value: 7, label: 'Juli'},
        { value: 8, label: 'August'},
        { value: 9, label: 'September'},
        { value: 10, label: 'Oktober'},
        { value: 11, label: 'November'},
        { value: 12, label: 'Dezember'},
    ]

    const possibleYears = [
        { value: 2021, label: '2021'},
        { value: 2022, label: '2022'},
        { value: 2023, label: '2023'},
        { value: 2024, label: '2024'},
        { value: 2025, label: '2025'},

    ]

    let possibleCompanies = [
        { value: '', label: 'Nicht filtern'},
        { value: 'Northern Access GmbH', label: 'Northern Access GmbH'},
        { value: 'G.A.A. mbH', label: 'G.A.A. mbH'},
        { value: 'Samtgemeinde Weser-Aue', label: 'Samtgemeinde Weser-Aue'},
        { value: 'SuHo', label: 'SuHo'},
        { value: 'Weserdruckerei', label: 'Weserdruckerei'},
        { value: 'GIM GmbH', label: 'GIM GmbH'},
        { value: 'Pfarramt Liebenau', label: 'Pfarramt Liebenau'},
        { value: 'TMS', label: 'TMS'},
        { value: 'Paul Boris', label: 'Paul Boris'},
        { value: 'Nesemann', label: 'Nesemann'},
        { value: 'Nexiu', label: 'Nexiu'},
        { value: 'D.A.W.', label: 'D.A.W.'},
        { value: 'Santech GmbH', label: 'Santech GmbH'},
        { value: 'Hafen Seelze', label: 'Hafen Seelze'},
        { value: 'Sonstige', label: 'Sonstige'},
        { value: 'Leseberg', label: 'Leseberg'},
        { value: 'Toja', label: 'Toja'},
        { value: 'Dr. Nuesken', label: 'Dr. Nuesken'},
    ]

    possibleCompanies = possibleCompanies.sort((a, b) => a.value.localeCompare(b.value));




    const [workUnitTable, setWorkUnitTable] = useState([{}]);
    const [selectedCompany, changeCompany] = useState("");
    const [selectedMonth, setMonth] = useState(possibleMonths.find(m => m.value === new Date().getMonth()+1));
    const [selectedYear, setYear] = useState(possibleYears.find(y => y.value === new Date().getFullYear()));

    const exportWU = async () =>{

        let exportText = ``;
        let currentDate = ""
        for(let wuE of workUnitTable){
            if(wuE.billed === 1)
                continue;
            if(currentDate != wuE.date_selected){
                exportText += `\n`
                currentDate = wuE.date_selected;
                exportText += `${Utils.formatDateNoHour(wuE.date_selected)}\n`
            }
            exportText += `${wuE.wu < 10 ? " "+wuE.wu : wuE.wu}AE - ${wuE.description}\n`
        }

        console.log(exportText)

        navigator.clipboard.writeText(exportText).then(Toastr.success("In die Zwischenablage exportiert."))

    }
    const recordWU = () =>{

        const name = `Arbeitseinheit erfassen`;

        const wuCaptureWindow = {
            name,
            component: <WorkUnitFrame
                refreshTable={refreshTable}
                possibleCompanies={possibleCompanies.filter(x => x.value !== "")}
                closeWindow={props.closeWindow}
                openWindow={props.openWindow}

            />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 600,
            minHeight: 400,
            x: 1,
            y: 1
        }
        props.openWindow(wuCaptureWindow);

    }

    const setBilled = (record) =>{
        if(record.billed === 1)
            return;
        const payload = {
            id : record.id,
            billed : 1
        }
        BillingService.setBilledWorkUnit(payload).then((response) => {
            refreshTable()
        })
    }
    const editWU = (wu) =>{

        const name = `Arbeitseinheit bearbeiten #`+wu.id;

        const wuEditWindow = {
            name,
            component: <WorkUnitFrame
                refreshTable={refreshTable}
                possibleCompanies={possibleCompanies.filter(x => x.value !== "")}
                description={wu.description}
                date_selected={wu.date_selected}
                company={wu.company}
                wu={wu.wu}
                id={wu.id}
                closeWindow={props.closeWindow}
                openWindow={props.openWindow}

            />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 600,
            minHeight: 400,
            x: 600,
            y: 700
        }
        props.openWindow(wuEditWindow);

    }


    const refreshTable = () =>{

        BillingService.getWorkUnitsSummary(selectedCompany.value, selectedMonth.value, selectedYear.value).then((response) => {
            setWorkUnitTable(response.data)
        })
    }
    useEffect(() => {
        refreshTable()
    }, [])
    useEffect(() => {
        refreshTable()
    }, [selectedCompany, selectedMonth, selectedYear])
    return (
        <div>


            <Row>
                <Col xs="4">
                    <Select
                        name="groups"

                        options={possibleCompanies}
                        className="basic-multi-select"
                        onChange={(el) => {
                            changeCompany(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Nach Firma filtern"}
                        classNamePrefix="select"
                    />


                </Col>

                <Col xs="3">
                    <Select
                        name="groups"
                        options={possibleMonths}
                        defaultValue={possibleMonths.find(m => m.value === new Date().getMonth()+1)}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setMonth(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Nach Monat filtern"}
                        classNamePrefix="select"
                    />
                </Col>

                <Col xs="3">
                    <Select
                        name="groups"
                        options={possibleYears}
                        defaultValue={possibleYears.find(y => y.value === new Date().getFullYear())}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setYear(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Nach Jahr filtern"}
                        classNamePrefix="select"
                    />
                </Col>
                <Col xs="2" className="float-end">
                    <ButtonDropdown isOpen={actionMenuOpen} toggle={() => {
                        toggleActionMenu(!actionMenuOpen)
                    }}>
                        <Button id="action" color="success" onClick={recordWU}>Hinzufügen</Button>
                        <DropdownToggle caret color="secondary" />
                        <DropdownMenu className={"bg-dark"}>
                            <DropdownItem className={"bg-dark"} header>Weitere Aktionen</DropdownItem>
                            <DropdownItem className={"bg-dark text-white"} onClick={exportWU}>CAO Export</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </Col>


            </Row>
            <Table className="table-dark table-striped table-hover table-borderless table- mt-5">
                <thead className="position-sticky top-0 bg-darker">
                <tr>
                    <th className="position-sticky top-0 bg-darker">#</th>
                    <th className="position-sticky top-0 bg-darker">Beschreibung</th>
                    <th className="position-sticky top-0 bg-darker">AE</th>
                    <th className="position-sticky top-0 bg-darker">Zeitpunkt</th>
                    <th className="position-sticky top-0 bg-darker">Firma</th>
                    <th className="position-sticky top-0 bg-darker">Mitarbeiter</th>
                    <th className="position-sticky top-0 bg-darker">Aufgenommen</th>
                    <th className="position-sticky top-0 bg-darker"></th>
                    <th className="position-sticky top-0 bg-darker">Aktion</th>

                </tr>
                </thead>
                <tbody>
                {
                    workUnitTable.map((record, i) =>
                        <tr>
                            <th scope="row">{record.id}</th>
                            <td>{record.description}</td>
                            <td>{record.wu}</td>
                            <td>{Utils.formatDateNoHour(record.date_selected)}</td>
                            <td>{record.company}</td>
                            <td>{record.employee}</td>
                            <td>{Utils.formatDate(record.date_added)}</td>
                            <td className={"text-center " + (record.billed === 1 ? "text-success" : "text-danger" )}><a href="#toggle" onClick={() => {setBilled(record)}}>{record.billed === 1 ? "✔" : "abrechnen"}</a></td>
                            <td>
                                <Button variant="outline-dark" className="me-1"  size="sm" onClick={() => {
                                    editWU(record)
                                }} >
                                    <FontAwesomeIcon  icon={faPencilAlt} />
                                </Button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </div>
    )
};

export default WorkUnitManagement;