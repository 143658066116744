import api from './api';
class BillingService {
    getWorkUnitsSummary(selectedCompany, selectedMonth, selectedYear) {
        return api.get('/billing/workunits/summary?selectedCompany='+selectedCompany+"&selectedMonth="+selectedMonth+"&selectedYear="+selectedYear);
    }
    getAutoComplete(query) {
        return api.get('/billing/workunits/getAutocomplete?query='+query);
    }
    captureWorkUnit(payload) {
        return api.post("/billing/workunits/capture", payload);
    }
    editWorkUnit(payload) {
        return api.post("/billing/workunits/edit", payload);
    }
    setBilledWorkUnit(payload) {
        return api.post("/billing/workunits/setBilled", payload);
    }

    createXLSXFromLines(payload) {
        return api.post("/billing/createXLSXFromLines", payload, {responseType: 'arraybuffer'});
    }

}
export default new BillingService();