import axios from "axios";
import TokenService from "./token.service";
import authService from "./auth.service";
const instance = axios.create({
    baseURL: window.location.hostname.includes("cva") ? "https://api.cva.de" : "http://10.20.2.10:3001",
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
             config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const handleSuccessResponse = response => {
    if (response) {
        response.success = response.status === 200;

        if (response.data.notice) {
            console.log("notice response")
            console.log(response.data.notice)

            response.data.err ? Toastr.error(response.data.notice) : Toastr.success(response.data.notice);
        }
    }
    return response;
};

import Toastr from "../components/notifications/Toastr";
const DEFAULT_ERROR_NOTIFICATION = "Etwas ist schief gelaufen!";


instance.interceptors.response.use(

        handleSuccessResponse
    ,
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token abgelaufen, refresh
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    console.log(TokenService.getLocalRefreshToken())
                    let rs;
                    try {
                        rs = await instance.post("/auth/refresh", {
                            refresh: TokenService.getLocalRefreshToken(),
                        });
                    } catch (e){
                        if(e.response.status === 410){
                          return location.reload();
                        }
                    }


                    const { accessToken } = rs.data;
                    TokenService.updateLocalAccessToken(accessToken);
                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            else if(err.response.status === 410){
                authService.logout()
            }
            //else alert("Fehler!")
        }
        return Promise.reject(err);
    }
);
export default instance;