import {useEffect, useState} from 'react';
import {
    Button, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import AlgalonService from "../../../services/algalon.service";
import Utils from "../../Utils";
import HostEdit from "../NetworkManagement/HostEdit";



const CurrentProblemsSummary = (props) => {

    const [currentIssuesData, setCurrentIssuesData] = useState([{}]);

    const editHost = (host) =>{


        const name = `Host Detail Bearbeiten #${host.id} - ${host.address}/32`;


        const editHostWindow = {
            name,
            component: <HostEdit
                id={host.id}
                refreshTable={() => refreshAlarmTable()}
                openWindow={props.openWindow}
                closeNameWindow={(c) => {
                    console.log("props")
                    console.log(props)
                    console.log("c")
                    console.log(c)

                    props.closeWindow2(c)


                }}
                closeWindow={() => props.closeWindow(name)}
            />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 950,
            minHeight: 930,
            x: 1,
            y: 1
        }

        props.openWindow(editHostWindow);
    }

    const clearAlert = (id) => {
        AlgalonService.clearAlert(id).then((response) => {
            console.log(response.data)
            refreshAlarmTable()
        })
    }

    const refreshAlarmTable = () =>{
        AlgalonService.getAlgalonData(props.net).then((response) => {
            console.log(response.data.items)
            setCurrentIssuesData(response.data.items)
        })

    }
    useEffect(() => {
        refreshAlarmTable()

        const interval = setInterval(() => {
            refreshAlarmTable()
        }, 15000);
        return () => clearInterval(interval);
        }, [])
    return (
        <Table className="table-dark table-striped table-hover table-borderless">
            <thead>
            <tr>
                <th className="position-sticky top-0 bg-darker">H ID</th>
                <th className="position-sticky top-0 bg-darker">H Name</th>
                <th className="position-sticky top-0 bg-darker">H Adresse</th>
                <th className="position-sticky top-0 bg-darker">Typ</th>
                <th className="position-sticky top-0 bg-darker">Meldung</th>
                <th className="position-sticky top-0 bg-darker">Zeitpunkt</th>

            </tr>
            </thead>
            <tbody>
            {
                currentIssuesData.map((CI, i) =>
                    <tr>
                        <th scope="row"
                            onClick={
                                () => {
                                    editHost(CI)
                            }
                            }

                        >{CI.host_id}</th>
                        <td


                            onClick={() => {
                                clearAlert(CI.newest_id)

                            }}
                            style={{cursor: 'pointer',} }

                        >{CI.name}</td>
                        <td>{CI.address}</td>
                        <td className={CI.newest_type === 'error' ? 'text-danger' : 'text-warning'}>{CI.newest_type}</td>
                        <td>{CI.newest_message}</td>
                        <td>{Utils.formatDate(new Date(CI.newest_timestamp))}</td>

                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default CurrentProblemsSummary;