import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import BillingService from "../../../services/billing.service";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import de from 'date-fns/locale/de';
import moment from "moment";
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";
import UserService from "../../../services/user.service";

registerLocale('de', de)
const WorkDayEditFrame = (props) => {

    const wuCountRef = useRef();



    const [reason, setReason] = useState({ value: '', label: 'Keine'})

    const editDayValue = () => {
        const payload = {
            empID : props.empID,
            day :    props.day,
            selectedReason : reason.value,
        }

        UserService.editWorkDayValue(payload).then((response) => {
            if(props.refreshTable) {
                props.refreshTable();
            }
            if(props.closeWindow)
                props.closeWindow()
        })
    }

    useEffect(() => {
        console.log(props)
    }, [])

    return (
        <Form inline>
            <FormGroup row>
                <Col sm={12} className="pt-1 pb-1">
                    <Label for="selectedDate">Auswahl:</Label>

                    <Select
                        name="groups"
                        defaultValue={{ value: '', label: 'Keine'}}
                        options={props.possibleDayOptions}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setReason(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Grund auswählen"}
                        classNamePrefix="select"
                    />
                </Col>

            </FormGroup>

            {

               <Button color="success" className="mt-2 float-end" onClick={editDayValue}>Speichern</Button>
            }

        </Form>
    )
};

export default WorkDayEditFrame;