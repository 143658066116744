import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import NavBar from './Navbar';
import DragAndResizeableWindow from "../DragAndResizeableWindow";
import LoginForm from "./LoginForm";
import UserControl from "../windows/UserControl/UserControl";

class Content extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }



  render() {
    return (
      <Container fluid className={classNames('content', { 'is-open': this.props.isOpen }, "bg-content")}>
        <NavBar toggle={this.props.toggle}  openWindow={this.props.openWindow} openWindows={this.props.openWindows} maximizeWindow={this.props.maximizeWindow}/>
          {this.props.openWindows.map((window, i) =>

              <DragAndResizeableWindow isOpen={window.open} name={window.name} closeWindow={this.props.closeWindow} minimizeWindow={this.props.minimizeWindow}
                                       width={window.width ?
                                           window.width :
                                           undefined}
                                       height={window.height ?
                                            window.height :
                                           undefined}
                                       minWidth={window.minWidth ?
                                           window.minWidth :
                                           undefined}
                                       minHeight={window.minHeight ?
                                           window.minHeight :
                                           undefined}
                                       x={window.x ?
                                           window.x :
                                           undefined}
                                       y={window.y ?
                                           window.y :
                                           undefined}
              >{window.component}</DragAndResizeableWindow>

          )}


      </Container>
    );
  }
}

export default Content;
