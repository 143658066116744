import {useEffect, useRef, useState} from 'react';
import {
    Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import BillingService from "../../../services/billing.service";
import Utils from "../../Utils";
import Select from "react-select";
import WorkUnitFrame from "./WorkUnitFrame";
import Toastr from "../../notifications/Toastr";
import NetManagementService from "../../../services/net_management.service";

import fileSaver from 'file-saver';


const TiefbauLinieToXLSX = (props) => {

    const [lines, setLines] = useState([]);

    const [rechnungsnummer, setRechnungsnummer] = useState("230XXX");
    const [rechnungsdatum, setRechnungsdatum] = useState(new Date());
    const [rechnungsindex, setRechnungsindex] = useState(1);

    const[downloadLink, setDownloadLink] = useState(null);

    const createXLSX = async () => {
        BillingService.createXLSXFromLines({lines: lines,
            rechnungsnummer: rechnungsnummer,
            rechnungsdatum: rechnungsdatum,
            rechnungsindex: rechnungsindex,


        }).then((response) => {
            /**
             * backend:
             *
             *           console.log('Wert erfolgreich geschrieben und Datei gespeichert.');
             *           res.sendFile(join(__dirname, '../datei.xlsx'));
             */

            const dateiName = decodeURIComponent(response?.headers['content-type'])?.split('FileName=')[1]+" (generiert).xlsx";

            console.log(response)
            const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
            const url = URL.createObjectURL(blob);
            setDownloadLink(url);
            fileSaver.saveAs(blob, dateiName);



        }).catch((e) => {

            const errorMessage = e?.response?.data?.notice || "XLSX konnte nicht erstellt werden";
            Toastr.error(errorMessage);
        });
        //api call
    }

    useEffect(() => {
        //bsp wert 2023-08-04
        const dateString = rechnungsdatum.toISOString().split('T')[0];
        setRechnungsdatum(dateString);
    } ,[])

    return (
        <div>


            <Row>
                {
                    /**
                     * backend: erwartet Rechnungsnnummer, Rechnungsdatum und Rechnungsindex
                     * frontend: Rechnungsnummer, Rechnungsdatum und Rechnungsindex als Input
                     */
                }

                <Col xs="3">
                    <h3>Rechnungsnummer</h3>
                    <Input
                        type="text"
                        name="text"
                        id="rechnungsnnr"
                        value={rechnungsnummer}
                        onChange={(e) => {
                            setRechnungsnummer(e.target.value);
                        }
                        }
                    />
                </Col>
                <Col xs="3">
                    <h3>Rechnungsdatum</h3>
                    <Input
                        type="date"
                        name="text"
                        id="rechnungsdatum"
                        value={rechnungsdatum}
                        onChange={(e) => {
                            setRechnungsdatum(e.target.value);
                        }
                        }
                    />
                </Col>
                <Col xs="3">
                    <h3>Rechnungsindex</h3>
                    <Input
                        type="text"
                        name="text"
                        id="rechnungsindex"
                        value={rechnungsindex}
                        onChange={(e) => {
                            setRechnungsindex(e.target.value);
                        }
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="1">
                </Col>
                <Col xs="10">
                    <h3>Bitte QGIS Tabellen Kopie einfügen und bestätigen</h3>
                    <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        rows="10"
                        onChange={(e) => {
                            setLines(e.target.value.split("\n"));
                        }}

                    />

                </Col>


                <Col xs="1">
                </Col>


                <Col xs="5">
                </Col>
                <Col xs="2" className={"mt-5"}>
                    <Button color="primary" onClick={createXLSX}>Bestätigen</Button>
                </Col>
            </Row>

        </div>
    )
};

export default TiefbauLinieToXLSX;