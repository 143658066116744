import React from "react";
import {
    CardBody,
    Card,
    CardSubtitle,
    CardText,
    CardLink,
    CardTitle, CardHeader, Button, CardFooter
} from "reactstrap";

import { Resizable } from 'react-resizable';
import { ResizableBox } from 'react-resizable';
import {Rnd} from "react-rnd";
import OutsideReactor from "./OutsideReactor";


export default class DragAndResizeableWindow extends React.Component {
    
    state = {
        showModal: true,
        width :"100px",
        height: "100px",
        zIndex: 100,
        inBackground: true
    };

    calculateNewZIndex = () => {
            const now = new Date();
        var yesterday = new Date(2022, 1, 1, 0, 0, 0, 0);

            const time = now.getTime() / 100;
            const endtime = yesterday.getTime() / 100;


            const result = Math.floor(time - endtime);

            return result;
    }
    modalClose = () => {
        this.setState({
            modalShow: false
        });
    };
    handleClick = () => {
      //  console.log(this.props.name + " handle click")
      //  console.log(this.context)

        this.setState(prevState => ({
            inBackground: false,
            zIndex : this.calculateNewZIndex()
        }));

    };
    toBackground = () =>{

        if(!this.state.inBackground){
           // console.log(this.props.name + " click somewhere else")
           // alert("to background")

            this.setState(prevState => ({
                inBackground: true,
               /* zIndex : 10*/
            }));
        }

    };
    componentDidMount() {

        this.setState(prevState => ({
            inBackground: false,
            zIndex : this.calculateNewZIndex()
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.calculateNewZIndex()
    }


    onStart = () => {
        console.log("here");
    };

    render() {
        return (
            <div>

                    <Rnd style={{zIndex : this.state.zIndex, display : this.props.isOpen ? "" : "none"}}
                        default={{
                            x: this.props.x ? this.props.x : 500,
                            y: this.props.y ? this.props.y : 600,
                            width: this.props.width ? this.props.width : 800,
                            height: this.props.height ? this.props.height : 600,
                        }}
                        minWidth={this.props.minWidth ? this.props.minWidth : 600}
                         minHeight={this.props.minHeight ? this.props.minHeight : 500}
                        dragHandleClassName={"drag-bar"}
                    >
                        <Card style={{height: "100%"}} onMouseUp ={this.handleClick} onMouseDown ={this.handleClick} onClick ={this.handleClick}>
                            <CardHeader className="drag-bar bg-dark text-white">
                                {this.props.name}
                                <Button color="danger" className="float-end" onClick={() => this.props.closeWindow(this.props.name) }> X</Button>
                                <Button color="secondary" className="float-end me-1" onClick={() => this.props.minimizeWindow(this.props.name) }> 🗕</Button>

                            </CardHeader>
                            <CardBody className="bg-dark text-white" style={{overflow: "auto"}}>
                                {this.props.children }
                            </CardBody>
                            <CardFooter className="bg-dark text-white">
                            </CardFooter>
                        </Card>
                    </Rnd>

            </div>

        );
    }
}
/**
 *  <!-- <OutsideReactor toBackground={this.toBackground}> -->
 *      <!--  </OutsideReactor> -->
 */