import {useEffect, useState} from "react";
import {Buffer} from 'buffer';

import BackupCheckService from "../../../services/backup_check.service";


const BackupReportHTML = (props) => {

    const [htmlContent, changeHtmlContent] = useState("<p>Inhalt wird geladen...</p>")

    useEffect(() => {
        BackupCheckService.getHTMLReport(props.uid).then((response) => {


            if(response.data.content.toString().includes("Content-Transfer-Encoding: base64"))
                response.data.content = response.data.content.toString().split("Content-Transfer-Encoding: base64")[1].split(" ")[0]


            if(Buffer.from(response.data.content.toString(), 'base64').toString('base64').trimStart().substring(0, 15) === response.data.content.toString().trimStart().substring(0, 15)){
                response.data.content = Buffer.from(response.data.content.toString(), 'base64').toString().replaceAll("\\n", "<br>").replaceAll("\n", "<br>")
            }


            changeHtmlContent(response.data.content)
        })

    }, [])


    return (
        <div dangerouslySetInnerHTML={{__html: htmlContent}} />
    )
};

export default BackupReportHTML;