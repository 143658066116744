import api from './api';
class AlgalonService {

    data = []
    constructor(props) {

        //return () => clearInterval(interval);
      /*  this.updateAlgalonData = this.updateAlgalonData.bind(this);
        setInterval(this.updateAlgalonData, 10000);
        this.updateAlgalonData();

        this.getAlgalonData = this.getAlgalonData.bind(this);

        console.log(`Init Algalon Service`)*/
    }


    updateAlgalonData(){
        api.get('/algalon/get/alertCounts').then((result) => {
            console.log("result.data")
            console.log(result.data)
            this.data = result.data;
        });

    }
    getAlgalonData() {
        return api.get('/algalon/get/currentIssues');
    }

    clearAlert(id) {
        return api.get('/algalon/clearAlert/'+id);
    }

}
export default new AlgalonService();