import {useEffect, useState} from "react";
import {Buffer} from 'buffer';

import BackupCheckService from "../../../services/backup_check.service";
import Utils from "../../Utils";
import {Button, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";


const BackupReportTrilead = (props) => {

    const [trileadReport, setTrileadReport] = useState([])


    useEffect(() => {
        BackupCheckService.getTrileadReport(props.uid).then((response) => {
            setTrileadReport(response.data)
        })

    }, [])


    return (
        <Table className="text-white bg-dark">
            <thead>
            <tr>
                <th>Task</th>
                <th>Ergebnis</th>
                <th>Details</th>
                <th>Dauer</th>
                <th>Eingang</th>
                <th>Startzeit</th>
                <th>Endzeit</th>
                <th>Transfergröße</th>
                <th>Transferrate</th>
                <th>Quell-Server</th>
                <th>Ziel-Server</th>
                <th>Ziel-Ort</th>
            </tr>
            </thead>
            <tbody>
            {
                trileadReport.length > 0 && trileadReport.map((report, i) =>
                    <tr>
                        <th scope="row">{report.task}</th>
                        <td className={"text-"+report.color}>{report.result}</td>
                        <td>{report.details}</td>
                        <td>{report.duration}</td>
                        <td className={"text-center"}>{Utils.formatDate(report.date)}</td>
                        <td>{report.starttime}</td>
                        <td>{report.endtime}</td>
                        <td>{report.transferedSize}</td>
                        <td>{report.transferRate}</td>
                        <td>{report.sourceServer}</td>
                        <td>{report.targetServer}</td>
                        <td>{report.targetLocation}</td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default BackupReportTrilead;