import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import Select from 'react-select';
import NetDetail from "./NetDetail";
import NetManagementService from "../../../services/net_management.service";
import HostCreditsEdit from "./HostCreditsEdit";


const HostEdit = (props) => {


    const descriptionRef = useRef();


    const saveNetwork = (host) =>{
        const payload = {
            id : props.id,
            description : descriptionRef.current.value,
        }

        NetManagementService.saveNetwork(payload).then((response) => {
            if(props.refreshTable) {
                props.refreshTable();
            }
            if(props.closeWindow)
                props.closeWindow()
        })
    }
    useEffect(() => {
    }, [])



    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={12} className="pt-1 pb-1">
                    <Label for="firstName">Beschreibung:</Label>
                    <Input type="text" innerRef={descriptionRef} defaultValue={props.description} name="description" id="description"/>
                </Col>
            </FormGroup>
            <Button color="success" onClick={() => {saveNetwork()}} className="mt-2 float-end">Speichern</Button>

        </Form>
    )
};

export default HostEdit;