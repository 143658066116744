import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import Select from 'react-select';
import NetDetail from "./NetDetail";
import NetManagementService from "../../../services/net_management.service";
import HostCreditsEdit from "./HostCreditsEdit";
import Toastr from "../../notifications/Toastr";


const HostEdit = (props) => {
    const [hostData, setHostData] = useState([{}]);
    const [monitored, setMonitored] = useState([false]);
    const [specialType, setSpecialType] = useState("none");
    const [monitorDisk, setMonitorDisk] = useState([false]);

    const nameRef = useRef();
    const hostnameRef = useRef();
    const descriptionRef = useRef();
    const monitoring_prioRef = useRef();
    const monitoring_retryRef = useRef();
    const monitoring_timeoutRef = useRef();

    const webservice_url_intern_inputRef = useRef();
    const webservice_url_extern_inputRef = useRef();


    const saveHost = (host) =>{
        const payload = {
            id : host.id,
            description : descriptionRef.current.value,
            name : nameRef.current.value,
            monitored,
            special_type: specialType,
            hostname: hostnameRef.current.value,
            monitoring_prio : monitoring_prioRef.current ? monitoring_prioRef.current.value : host.monitoring_prio,
            monitoring_retry : monitoring_retryRef.current ? monitoring_retryRef.current.value : host.monitoring_retry,
            monitoring_timeout : monitoring_timeoutRef.current ? monitoring_timeoutRef.current.value : host.monitoring_timeout,
            webservice_url_intern : webservice_url_intern_inputRef.current ? webservice_url_intern_inputRef.current.value : host.webservice_url_intern,
            webservice_url_extern : webservice_url_extern_inputRef.current ? webservice_url_extern_inputRef.current.value : host.webservice_url_extern,
            monitor_disk : monitorDisk,
        }

        NetManagementService.saveHost(payload).then((response) => {
            NetManagementService.reloadAlgalonHosts().then(r => Toastr.success(`Neue Konfiguration wurde an Algalon übermittelt.`)).catch(e => Toastr.error(`Fehler beim Übermitteln der Konfiguration an Algalon: ${e}.`))

            if(props.refreshTable) {
                props.refreshTable();
            }
            if(props.closeWindow)
                props.closeWindow()


        })
    }
    const loadHostData = () =>{
        NetManagementService.getHostByID(props.id).then((response) => {

            response.data.has_ssh_credits = response.data.has_ssh_credits === 1;

            setHostData(response.data)

            setMonitored(response.data.monitored === 1)
            setSpecialType(response.data.special_type)
            setMonitorDisk(response.data.monitor_disk === 1)
        })
    }

    useEffect(() => {
        loadHostData()
    }, [])


    const editHostCredits = (host, type) =>{


        const name = `Host ${type} Credits Bearbeiten #${host.id} - ${host.address}/32`;

        const editHostCreditsWindows = {
            name,
            component: <HostCreditsEdit
                id={host.id}
                type={type}
                refreshData={loadHostData}
                closeWindow={() =>{
                    props.closeNameWindow(name)
                }} />,
            open: true,
            minimized: false,
            width: 600,
            minWidth: 600,
            height: 600,
            minHeight: 400,
            x: 600,
            y: 1
        }

        props.openWindow(editHostCreditsWindows);
    }

    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="firstName">Name:</Label>
                    <Input type="text" innerRef={nameRef} defaultValue={hostData.name} name="name" id="name"/>
                </Col>
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="lastName">Hostname:</Label>
                    <Input type="text" innerRef={hostnameRef} defaultValue={hostData.hostname} name="hostname" id="hostname"/>
                </Col>
            </FormGroup>
            <FormGroup row className="mr-sm-2 mb-sm-0 mt-3">
                <Col sm={12} className="pt-1 pb-1">
                    <Label for="emailField">Beschreibung:</Label>
                    <Input type="email" innerRef={descriptionRef} defaultValue={hostData.description} name="description" id="description" />
                </Col>
            </FormGroup>
            <FormGroup row className="mt-5">
                <Col sm={4} className="pt-1 pb-1">
                <Label check>
                    <Input type="checkbox" className="text-white" innerRef checked={monitored} onChange={() => setMonitored(!monitored) }
                    />{' '}
                    Monitored
                </Label>
                </Col>
                <Col sm={8} className="pt-1 pb-1">
                {
                    hostData.has_ssh_credits ?
                        <div>
                            <Input type="checkbox" className="text-white" innerRef checked={monitorDisk}
                                   onChange={() => setMonitorDisk(!monitorDisk)}
                            />{' '}
                            <Label check>
                                Monitor Disk
                            </Label>
                        </div>
                    : <p>Zum Überwachen der Disk Usage bitte Zugangsdaten eintragen</p>
                }
                </Col>
            </FormGroup>
            {
                monitored &&
                <FormGroup row className="mb-2 mr-sm-2 mb-5">
                    <Col sm={4} className="pt-1 pb-1">
                        <Label for="firstName">Priorität:</Label>
                        <Input type="text" innerRef={monitoring_prioRef} defaultValue={hostData.monitoring_prio} name="monitoring_prio" id="monitoring_prio"/>
                    </Col>
                    <Col sm={4} className="pt-1 pb-1">
                        <Label for="lastName">Versuche:</Label>
                        <Input type="text" innerRef={monitoring_retryRef} defaultValue={hostData.monitoring_retry} name="monitoring_retry" id="monitoring_retry"/>
                    </Col>
                    <Col sm={4} className="pt-1 pb-1">
                    <Label for="lastName">Timeout (ms):</Label>
                    <Input type="text" innerRef={monitoring_timeoutRef} defaultValue={hostData.monitoring_timeout} name="monitoring_timeout" id="monitoring_timeout"/>
                </Col>
                </FormGroup>
            }

            <FormGroup row className="mb-2 mr-sm-2 mb-5">
                <Col sm={5} className="pt-1 pb-1">
                    <Label for="special_type_select">Spezieller Typ</Label>
                    <Input type="select" name="special_type_select" id="special_type_select" value={specialType} onChange={(event) => {setSpecialType(event.target.value)}} >
                        <option value={"none"}>Normal</option>
                        <option value={"proxmox_pve"}>Proxmox PVE</option>
                        <option value={"proxmox_pbs"}>Proxmox PBS</option>
                        <option value={"mysql_server"}>MySQL Server</option>
                        <option value={"postgresql_server"}>PostgreSQL Server</option>
                        <option value={"radius_server"}>Radius Server</option>
                    </Input>
                </Col>
                <Col sm={7} className="pt-4 pb-">
                    {
                        specialType == "mysql_server" ?  <Button color="info" className="mt-2 me-5" onClick={() => {editHostCredits(hostData, "MySQL")}}>MySQL Zugangsdaten</Button> :
                            specialType == "postgresql_server" ? <Button color="info" className="mt-2 me-5" onClick={() => {editHostCredits(hostData, "PostgreSQL")}}> PostgreSQL Zugangsdaten </Button> : ""
                    }
                </Col>
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-5">
                <Col sm={11} className="pt-1 pb-1">
                    <Label for="webservice_url_intern_input">Webservice URL Intern</Label>
                    <Input type="text" name="webservice_url_intern_input" id="webservice_url_intern_input" innerRef={webservice_url_intern_inputRef} defaultValue={hostData.webservice_url_intern} />
                </Col>
                <Col sm={11} className="pt-1 pb-1">
                    <Label for="webservice_url_extern_input">Webservice URL Extern</Label>
                    <Input type="text" name="webservice_url_extern_input" id="webservice_url_extern_input" innerRef={webservice_url_extern_inputRef} defaultValue={hostData.webservice_url_extern} />
                </Col>
            </FormGroup>
            <Button color="info" className="mt-2 me-5" onClick={() => {editHostCredits(hostData, "SSH")}}>Zugangsdaten</Button>
            <Button color="success" onClick={() => {saveHost(hostData)}} className="mt-2 float-end">Speichern</Button>

        </Form>
    )
};

export default HostEdit;