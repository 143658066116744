import {useEffect, useState} from 'react';
import {
    Button, Table,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import NetManagementService from "../../../services/net_management.service";
import NetDetail from "./NetDetail";
import NetDetailEdit from "./NetDetailEdit";


const NetSummary = (props) => {

    const [netSummaryTable, setNetSummaryTable] = useState([{}]);

    const openNetwork = (network) =>{

        const name = `Netzwerk Detail #${network.id} - ${network.net}/${network.mask}`;

        const htmlReportWindow = {
            name,
            component: <NetDetail
                id={network.id}
                closeWindow={props.closeWindow}
                openWindow={props.openWindow}

            />,
            open: true,
            minimized: false,
            width: 900,
            minWidth: 600,
            height: 600,
            minHeight: 400,
            x: 1,
            y: 1
        }
        props.openWindow(htmlReportWindow);

    }

    const editNetwork = (network) =>{

        const name = `Netzwerk Detail Bearbeiten #${network.id} - ${network.net}/${network.mask}`;

        const htmlReportWindow = {
            name,
            component: <NetDetailEdit
                id={network.id}
                description={network.description}
                refreshTable={refreshNetTable}
                closeWindow={props.closeWindow}
                openWindow={props.openWindow}

            />,
            open: true,
            minimized: false,
            width: 500,
            minWidth: 500,
            height: 600,
            minHeight: 400,
            x: 1,
            y: 1
        }
        props.openWindow(htmlReportWindow);

    }


    const refreshNetTable = () =>{
        NetManagementService.getNetDir(props.net).then((response) => {
            setNetSummaryTable(response.data)
        })
    }
    useEffect(() => {
        refreshNetTable()
    }, [])
    return (
        <Table className="table-dark table-striped table-hover table-borderless">
            <thead>
            <tr>
                <th className="position-sticky top-0 bg-darker">#</th>
                <th className="position-sticky top-0 bg-darker">Netz</th>
                <th className="position-sticky top-0 bg-darker">Beschreibung</th>
                <th className="position-sticky top-0 bg-darker">Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {
                netSummaryTable.map((ne, i) =>
                    <tr>
                        <th scope="row">{ne.id}</th>
                        <td>{ne.net}/{ne.mask}</td>
                        <td>{ne.description}</td>
                        <td>
                            <Button variant="outline-dark" className="me-1"  size="sm" onClick={() => {
                                editNetwork(ne)
                            }} >
                                <FontAwesomeIcon  icon={faPencilAlt} />
                            </Button>
                            <Button variant="outline-dark"  size="sm" onClick={() => {
                                openNetwork(ne)
                            }} >
                                <FontAwesomeIcon className="pe-1 ps-1" icon={faInfo} />
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
};

export default NetSummary;