import React from 'react';
import  { Collapse, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import UserControl from "../windows/UserControl/UserControl";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

class SubMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
     this.state = {
       collapsed: true
     };
   }





   toggleNavbar() {
     this.setState({
       collapsed: !this.state.collapsed,
     });
   }
   render() {
     const { icon, title, items, objects } = this.props;
     return (
       <div>
         <NavItem onClick={this.toggleNavbar} className={classNames({'menu-open': !this.state.collapsed})}>
           <NavLink className="dropdown-toggle" href="#"><FontAwesomeIcon icon={icon} className="me-2 nav-icon"/>{title}</NavLink>
         </NavItem>
         <Collapse isOpen={!this.state.collapsed} navbar className={classNames('items-menu',{'mb-1': !this.state.collapsed})}>
             {
                 objects && objects[0] && objects[0].optLink ?
                 objects.map(object => (
                     <NavItem key={object.name} className="ms-4">
                         <NavLink href={"#"+object.name} href={object.optLink} target={"_blank"} onClick={() => {this.props.handleClick(object.name)}}>{object.name} <a href={object.optLink} target={"_blank"}><FontAwesomeIcon icon={faExternalLinkAlt} className="nav-icon fa-pull-right"/></a> </NavLink>
                     </NavItem>
                 ))
                     :
                     items.map(item => (
                         <NavItem key={item} className="ms-4">
                             <NavLink href={"#"+item} onClick={() => {this.props.handleClick(item)}}>{item}</NavLink>
                         </NavItem>
                     ))
             }

         </Collapse>
       </div>
     );
   }
}

export default SubMenu;
