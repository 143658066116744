import React, {useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faBriefcase,
    faPaperPlane,
    faQuestion,
    faImage,
    faCopy,
    faSignOutAlt,
    faKiwiBird,
    faUserAlt,
    faEye,
    faNetworkWired,
    faInbox,
    faServer,
    faMoneyBill,
    faUsers,
    faNewspaper, faHistory, faCloud
} from "@fortawesome/free-solid-svg-icons";

import {AsyncTypeahead, Menu, MenuItem, Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import SubMenu from "./SubMenu";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import AuthService from "../../services/auth.service";
import UserControl from "../windows/UserControl/UserControl";
import BackupReportInbox from "../windows/BackupChecks/BackupReportInbox";
import BackupReportAccountControl from "../windows/BackupChecks/BackupReportAccountControl";
import NetSummary from "../windows/NetworkManagement/NetSummary";
import AlgalonService from "../../services/algalon.service";

import SmartSearchService from "../../services/smart_search.service";
import HostEdit from "../windows/NetworkManagement/HostEdit";
import WorkUnitManagement from "../windows/BillingManagement/WorkUnitManagement";
import WorkDayManagement from "../windows/EmployeeManagement/WorkDayManagement";
import WorkDayRequests from "../windows/EmployeeManagement/WorkDayRequests";
import TokenService from "../../services/token.service";
import Toastr from "../notifications/Toastr";
import algalonService from "../../services/algalon.service";
import CurrentProblemsSummary from "../windows/AlgalonMonitoring/CurrentProblemsSummary";
import TiefbauLinieToXLSX from "../windows/BillingManagement/TiefbauLinieToXLSX";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          isLoading: false
      }
  }

    handleClick = (item) => {

    switch (item){
        case "Algalon":
            this.props.openWindow(
                {
                    name : item,
                    component : <CurrentProblemsSummary closeWindow={this.closeWindow} openWindow={this.props.openWindow}   />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 800,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Wiki":
            this.props.openWindow(
                {
                    name : item,
                    component : <iframe id="if1" width="100%" height="254" style={{height: "100%", border: "none", overflow: "hidden"}}
                                        src="https://wiki.cva.de"></iframe>,
                    open : true,
                    minimized : false,
                    width: 1500,
                    minWidth: 1250,
                    height: 800,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Benutzerverwaltung":
            this.props.openWindow(
                {
                    name : "Benutzerverwaltung",
                    component : <UserControl openWindow={this.props.openWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Bericht Eingang":
            this.props.openWindow(
                {
                    name : "Backup Berichte Eingang",
                    component : <BackupReportInbox openWindow={this.props.openWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Bericht Verwaltung":
            this.props.openWindow(
                {
                    name : "Backup Berichte Verwaltung",
                    component : <BackupReportAccountControl openWindow={this.props.openWindow} />,
                    open : true,
                    minimized : false,
                    width: 1500,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Benutzerverwaltung":
            this.props.openWindow(
                {
                    name : "Benutzerverwaltung",
                    component : <UserControl openWindow={this.props.openWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "CVA Netze":
            this.props.openWindow(
                {
                    name : "CVA Netze",
                    component : <NetSummary net={"CVA"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Seelze Netze":
            this.props.openWindow(
                {
                    name : "Seelze Netze",
                    component : <NetSummary net={"SEELZE"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "NA Netze":
            this.props.openWindow(
                {
                    name : "NA Netze",
                    component : <NetSummary net={"NA"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "GAA Sulingen Netze":
            this.props.openWindow(
                {
                    name : "GAA Sulingen Netze",
                    component : <NetSummary net={"GAA-SULINGEN"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "GAA Barenburg Netze":
            this.props.openWindow(
                {
                    name : "GAA Barenburg Netze",
                    component : <NetSummary net={"GAA-BARENBURG"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "GAA Alte Netze":
            this.props.openWindow(
                {
                    name : "GAA Alte Netze",
                    component : <NetSummary net={"GAA-ALT"} openWindow={this.props.openWindow} closeWindow={this.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "AE Erfassung":
            if(TokenService.getGroups().includes("cva_emp")) {

                this.props.openWindow(
                    {
                        name: "AE Erfassung",
                        component: <WorkUnitManagement openWindow={this.props.openWindow}
                                                       closeWindow={this.props.closeWindow}/>,
                        open: true,
                        minimized: false,
                        width: 1200,
                        minWidth: 600,
                        height: 800,
                        minHeight: 550,
                        x: 250,
                        y: 1
                    })
            }
            else{
                Toastr.error("Keine Berechtigung.")

            }
            break;
        case "Linie zu XLSX":
            if(TokenService.getGroups().includes("cva_emp")) {

                this.props.openWindow(
                    {
                        name: "Linie zu XLSX",
                        component: <TiefbauLinieToXLSX openWindow={this.props.openWindow}
                                                       closeWindow={this.props.closeWindow}/>,
                        open: true,
                        minimized: false,
                        width: 1200,
                        minWidth: 600,
                        height: 800,
                        minHeight: 550,
                        x: 250,
                        y: 1
                    })
            }
            else{
                Toastr.error("Keine Berechtigung.")

            }
            break;
      default:
        case "Proxmox NA":
            this.props.openWindow(
                {
                    name : item,
                    component : <iframe id="if1" width="100%" height="254" style={{height: "100%", border: "none", overflow: "hidden"}}
                                        src="https://px.na-intern.net/#v1:0:18:4:::::::"></iframe>,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Proxmox CVA":
            this.props.openWindow(
                {
                    name : item,
                    component : <iframe id="if1" width="100%" height="254" style={{height: "100%", border: "none", overflow: "hidden"}}
                                        src="https://10.20.7.1:8006"></iframe>,
                    open : true,
                    minimized : false,
                    width: 1300,
                    minWidth: 1250,
                    height: 550,
                    minHeight: 550,
                    x: 1,
                    y: 1
                })
            break;
        case "Arbeitstagplanung":
            this.props.openWindow(
                {
                    name : item,
                    component : <WorkDayManagement  openWindow={this.props.openWindow} closeWindow={this.props.closeWindow} />,
                    open : true,
                    minimized : false,
                    width: 1200,
                    minWidth: 600,
                    height: 800,
                    minHeight: 550,
                    x: 250,
                    y: 1
                })
            break;
        case "Arbeitstagverwaltung":
            console.log(TokenService.getUser().user)
            if(TokenService.getGroups().includes("billing")){
                this.props.openWindow(
                    {
                        name : item,
                        component : <WorkDayRequests  openWindow={this.props.openWindow} closeWindow={this.props.closeWindow} />,
                        open : true,
                        minimized : false,
                        width: 1200,
                        minWidth: 600,
                        height: 800,
                        minHeight: 550,
                        x: 250,
                        y: 1
                    })
            }
            else {
                Toastr.error("Keine Berechtigung.")
            }
            break;
    }
  }
    render() {
    return (
      <div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="sidebar-header">
          <a color="info" onClick={this.props.toggle} style={{ color: "#fff" }}>
            &times;
          </a>
          <h3><img src={"/favicon.png"}></img> Admin</h3>

        </div>
        <Nav vertical className="list-unstyled pb-3">
          <p>{AuthService.getCurrentUser().user.firstName} {AuthService.getCurrentUser().user.lastName}</p>
            <AsyncTypeahead
                id={"smart-search"}
                className={"mb-2"}
                isLoading={this.state.isLoading}
                labelKey={option => `${option.address} / ${option.name}`}
                renderMenu={this._renderMenu}

                onSearch={(query) => {
                    this.setState({isLoading: true});
                    SmartSearchService.searchByString(query)
                        .then(res => {

                            console.log(res)
                            this.setState({
                            isLoading: false,
                            options: res.data.items,
                        })}


                        );
                }}
                placeholder="Nach Eintrag suchen..."
                emptyLabel={"Keine Treffer."}
                searchText={"Suche..."}
                promptText={"Eingeben zum Suchen..."}

                options={this.state.options}
            />
            <NavLink href="#Benutzerverwaltung" onClick={() => {this.handleClick("Benutzerverwaltung")}}>
                <FontAwesomeIcon icon={faUserAlt} className="me-2 nav-icon" />
                Benutzerverwaltung
            </NavLink>
            <SubMenu
                title="CVA Intern"
                icon={faUsers}
                items={["Arbeitstagplanung", "Arbeitstagverwaltung"]}
                handleClick={this.handleClick}
            />
            <SubMenu
                title="Backup Berichte"
                icon={faInbox}
                items={["Bericht Eingang", "Bericht Verwaltung"]}
                handleClick={this.handleClick}
            />
          <SubMenu
            title="Proxmox"
            icon={faServer}
            items={["Proxmox NA", "Proxmox CVA", "CVA-TS-Parent"]}
            objects={[
                {
                    name: "Proxmox CVA #1",
                    optLink: "https://prox1.intern.cva.de"
                },
                {
                    name: "Proxmox CVA #2",
                    optLink: "https://prox2.intern.cva.de"
                },
                {
                    name: "CVA-TS-Parent",
                    optLink: "https://prox-ts.intern.cva.de"
                },
                {
                    name: "CVA Backup 6TB",
                    optLink: "https://10.20.7.21:8007/#v1:0:18:4:::::::"
                },
                {
                    name: "Proxmox NA",
                    optLink: "https://px.na-intern.net"
                },
                {
                    name: "NA Backup 02",
                    optLink: "https://10.20.7.16:8007/#v1:0:18:4:::::::"
                },
                {
                    name: "NA PBF 4",
                    optLink: "https://10.20.7.17:8007/#v1:0:18:4:::::::"
                }
            ]}
            handleClick={this.handleClick}
          />
            <SubMenu
                title="Netze"
                icon={faNetworkWired}
                items={["CVA Netze", "NA Netze", "Seelze Netze", "GAA Sulingen Netze", "GAA Barenburg Netze", "GAA Alte Netze"]}
                handleClick={this.handleClick}
            />
            <SubMenu
                title="Rechnung"
                icon={faMoneyBill}
                items={["AE Erfassung", "Linie zu XLSX"]}
                handleClick={this.handleClick}
            />
          <NavItem>
            <NavLink href="https://nc.cva.de/f/465943" target={"_blank"}>
              <FontAwesomeIcon icon={faHistory} className="me-2 nav-icon" />
              Changelog
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://nc.cva.de/" target={"_blank"}>
              <FontAwesomeIcon icon={faCloud} className="me-2 nav-icon" />
              Cloud
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#wiki" onClick={() => {this.handleClick("Wiki")}}>
              <FontAwesomeIcon icon={faQuestion} className="me-2 nav-icon" />
              Wiki
            </NavLink>
          </NavItem>
            {
                /**
                 * <NavItem>
                 *                 <NavLink href="#" onClick={() => {this.handleClick("Algalon")}}>
                 *                     <FontAwesomeIcon icon={faEye} className="me-2 nav-icon " />
                 *                     <span className={algalonService.data.errorCount > 0 ? "text-danger" :  algalonService.data.warningCount > 0 ? "text-warning" : ""}>Algalon {algalonService.data.total_count > 0 ? algalonService.data.total_count +"x" : ""}</span>
                 *                 </NavLink>
                 *             </NavItem>
                 */
            }
          <NavItem>
            <NavLink href="#" onClick={() => {AuthService.logout(); this.props.trigger() }}>
              <FontAwesomeIcon icon={faSignOutAlt} className="me-2 nav-icon" />
              Ausloggen
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );

  }

    _renderMenu = (results, menuProps) => {
        return (
            <Menu {...menuProps} id={results.length}>
                {results.map((result, idx) => (
                    <MenuItem
                        key={result.id}
                        onClick={() => this._handleMenuClick(result)}
                        option={result}
                        position={idx}>
                        {`${result.address} / ${result.name}`}
                    </MenuItem>
                ))}
            </Menu>
        );
    };

  _handleMenuClick = (host) => {

      const name = `Host Detail Bearbeiten #${host.id} - ${host.address}/32`;


      const editHostWindow = {
          name,
          component: <HostEdit
              id={host.id}
              openWindow={this.props.openWindow}
              closeWindow={() => this.props.closeWindow(name)}
          />,
          open: true,
          minimized: false,
          width: 600,
          minWidth: 500,
          height: 950,
          minHeight: 930,
          x: 1,
          y: 1
      }

      this.props.openWindow(editHostWindow);
  }
}

export default SideBar;
