import {Component, useEffect, useRef, useState} from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button, Spinner,
} from 'reactstrap';


import './LoginForm.css';
import './LoginForm.scss';
import AuthService from "../../services/auth.service";
import TokenService from "../../services/token.service";

const LoginForm = (props) => {
    const form = useRef();
    const [email, setEmail] = useState(TokenService.getUser.email);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [random, setRandom] = useState(1);
    const [emailState, setEmailState] = useState('');
    const [passwordState, setPasswordState] = useState('');

    const quotes = [
            {quote : "“Good software, like wine, takes time.”", owner : "Joel Spolsky"},
            {quote : "“Make it work, make it right, make it fast.”", owner : "Kent Beck"},
            {quote : "“Software is a great combination between artistry and engineering.”", owner : "Bill Gates"},
            {quote : "“One of my most productive days was throwing away 1,000 lines of code.”", owner : "Ken Thompson"},
            {quote : "“See your world through my eyes: A universe so vast as to be immeasurable - incomprehensible even to your greatest minds.”", owner : "Algalon"},
        ]

    useEffect(() => {//Zufällige Zahl in der Größe des Arrays
        setRandom(Math.floor(Math.random() * quotes.length))
    }, [])

    const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
        AuthService.login(email, password).then(
            () => {
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);

                if(resMessage.includes("401")){
                    setEmailState('has-danger');
                    setPasswordState('has-danger');
                }
                setMessage(resMessage.includes("401") ? "Ungültige Eingabe!" : resMessage);
            }
        );
};
    const validateEmail = (e) => {
        const emailRex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailRex.test(e.target.value)) {
            setEmailState('has-success');
        } else {
            setEmailState('has-danger');
        }

        setEmail(e.target.value)

    }
    const validatePassword = (e) => {
        const pwRex =
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

        if (pwRex.test(e.target.value)) {
            setPasswordState('has-success');
        } else {
            setPasswordState('has-danger');
        }
        setPassword(e.target.value)
    }
        return (
            <>
            <div className="Login bg-login-form" style={{zIndex: 1000}}>
                <h2 className={"text-center"} ><img src={"/favicon.png"}></img> Admin | Login</h2>

                <Form className="form" onSubmit={handleLogin} ref={form}>
                    <FormGroup>
                        <Label>E-Mail</Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="email@cva.de"
                            valid={emailState === "has-success"}
                            invalid={emailState === "has-danger"}
                            value={email}
                            onChange={(e) => {
                                validateEmail(e);
                                handleChange(e);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Passwort</Label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            valid={passwordState === "has-success"}
                            invalid={passwordState === "has-danger"}
                            placeholder="********"
                            value={password}
                            onChange={(e) => {
                                validatePassword(e)
                                handleChange(e);
                            }
                            }
                        />
                    </FormGroup>
                    <div className="text-center">
                        <Button >Anmelden</Button>
                        <h3 className={"mt-3 text-danger"}>{message}</h3>
                        {loading &&
                            <Spinner animation="border" className="text-center" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                        <h5>
                            {quotes[random].quote}<br />
                            - {quotes[random].owner}
                        </h5>
                    </div>
                </Form>
            </div>

                <img
                    className="background"
                    src="https://images.pexels.com/photos/3075993/pexels-photo-3075993.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                />

                {
                    Array.from({ length: 100 }, (_, i) =>

                        <div className="circle-container" key={i}>
                            <div className="circle"></div>
                        </div>

                    )

                }



            </>
        )
};

export default LoginForm;