import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import BillingService from "../../../services/billing.service";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import de from 'date-fns/locale/de';
import moment from "moment";
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";

registerLocale('de', de)
const HostEdit = (props) => {

    const wuCountRef = useRef();



    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedCompany, setCompany] = useState()
    const [options, setOptions] = useState([])
    const [description, setDescription] = useState( "")
    const [isLoading, setIsLoading] = useState(false)

    const _handleMenuClick = (result) => {
        console.log("result")
        console.log(result)
        console.log(wuCountRef.current.value)

        wuCountRef.current.value = result.wu;
    }

    const _renderMenu = (results, menuProps) => {
        return (
            <Menu {...menuProps} id={results.length}>
                {results.map((result, idx) => (
                    <MenuItem
                        key={result.id}
                        option={result}
                        onClick={() => _handleMenuClick(result)}

                        position={idx}>
                        {`${result.description}`}

                    </MenuItem>
                ))}
            </Menu>
        );
    }
    const captureWU = (host) =>{

        if(description.length > 0 && wuCountRef.current.value.length > 0 && selectedCompany.value ){

            const payload = {
                description : description,
                wu : wuCountRef.current.value,
                selectedDate :    moment(selectedDate).add("2", "h").toDate(),
                selectedCompany : selectedCompany.value,
            }

            BillingService.captureWorkUnit(payload).then((response) => {
                if(props.refreshTable) {
                    props.refreshTable();
                }
                if(props.closeWindow)
                    props.closeWindow()
            })

        }
    }

    const editWU = () =>{
        const payload = {
            id : props.id,
            description : description,
            wu : wuCountRef.current.value,
            selectedDate :    moment(selectedDate).add("2", "h").toDate(),
            selectedCompany : selectedCompany.value,
        }

        BillingService.editWorkUnit(payload).then((response) => {
            if(props.refreshTable) {
                props.refreshTable();
            }
            if(props.closeWindow)
                props.closeWindow()
        })
    }

    useEffect(() => {
        if(props.date_selected){
            setSelectedDate(new Date(props.date_selected))
        }
        if(props.company){
            setCompany(props.possibleCompanies.find(c => c.value === props.company))
        }
        console.log(props)
        if(props.description){
            setDescription(props.description)
        }
    }, [])

    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={9} className="pt-1 pb-1">
                    <Label for="firstName">Beschreibung:</Label>

                    <AsyncTypeahead
                        name="description"
                        id="description"
                        className={"mb-2"}
                        isLoading={isLoading}
                        renderMenu={_renderMenu}
                        labelKey={option => `${option.description}`}

                        onChange={(e) => {
                            if(e.length)
                            setDescription(e[0].description)
                            if(e.target)
                                setDescription(e.target.value)

                        }}
                        onSearch={(query) => {
                            setIsLoading(true)
                            setDescription(query)
                            console.log("query")

                            BillingService.getAutoComplete(query)
                                .then(res => {


                                    console.log(res.data)

                                        setOptions(res.data)
                                    setIsLoading(false)

                                    }
                                );
                        }}
                        placeholder=""
                        emptyLabel={""}
                        searchText={""}
                        promptText={""}
                        defaultInputValue={props.description ? props.description : ""}

                        options={options}
                    />
                </Col>
                <Col sm={3} className="pt-1 pb-1">
                    <Label for="lastName">AE:</Label>
                    <Input type="number"  required innerRef={wuCountRef} defaultValue={props.wu ? props.wu : ""}  name="wu" id="wu"/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={4} className="pt-1 pb-1">
                    <Label for="selectedDate">Zeitpunkt:</Label>
                    <DatePicker locale="de" className="form-control" selected={selectedDate} onChange={(date) => setSelectedDate(date)} name="selectedDate" id="selectedDate" />
                </Col>

                <Col sm={8} className="pt-1 pb-1">
                    <Label for="selectedDate">Für Firma:</Label>

                    <Select
                        name="groups"
                        defaultValue={props.possibleCompanies.find(c => c.value === props.company)}
                        options={props.possibleCompanies}
                        className="basic-multi-select"
                        onChange={(el) => {
                            setCompany(el)
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        placeholder={"Firma auswählen"}
                        classNamePrefix="select"
                    />
                </Col>

            </FormGroup>

            {
                props.description && props.description.length > 0 ?
                    <Button color="success" onClick={() => {editWU()}} className="mt-2 float-end">Speichern</Button> :
               <Button color="success" onClick={captureWU} className="mt-2 float-end">Erfassen</Button>
            }

        </Form>
    )
};

export default HostEdit;