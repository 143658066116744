import React, { useEffect, useState } from "react";
import {Container, Row, Col, ListGroup, ListGroupItem, Button} from "reactstrap";
import MapComponent from "../../MapContent/MapComponent";
import VermessungPointMarker from "../../MapContent/VermessungPointMarker";
import Toastr from "../../notifications/Toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import TiefbauService from "../../../services/tiefbau.service";
import {GeoJSON, Polyline} from "react-leaflet";

const PointToLine = (props) => {
    const [selectedPoints, setSelectedPoints] = useState([]);

    // Function to add a selected point to the array
    const handleAddPoint = (point) => {
        if (selectedPoints.some((selectedPoint) => selectedPoint.id === point.id)) {
            Toastr.error("Punkt mit ID " + point.id + " ist bereits ausgewählt");
            return;
        }
        setSelectedPoints((prevPoints) => [...prevPoints, point]);
    };

    // Function to remove a point from the array
    const handleRemovePoint = (p) => {
        setSelectedPoints((prevPoints) => {
            return prevPoints.filter((point) => point.id !== p.id);
        });
    };

    // Function to move a point up in the array
    const handleMoveUp = (index) => {
        if (index > 0) {
            setSelectedPoints((prevPoints) => {
                const newPoints = [...prevPoints];
                [newPoints[index - 1], newPoints[index]] = [newPoints[index], newPoints[index - 1]];
                return newPoints;
            });
        }
    };

    // Function to move a point down in the array
    const handleMoveDown = (index) => {
        if (index < selectedPoints.length - 1) {
            setSelectedPoints((prevPoints) => {
                const newPoints = [...prevPoints];
                [newPoints[index], newPoints[index + 1]] = [newPoints[index + 1], newPoints[index]];
                return newPoints;
            });
        }
    };

    const constructionTypes = [
        {
            id: 1,
            name: "Ungebunden",
            color: "#ff0000",
        },
        {
            id: 2,
            name: "Gebunden",
            color: "#00ff00",
        },
        {
            id: 3,
            name: "Schießen",
            color: "#0000ff",
        },
    ];

    const [vermessenePunkte, setVermessenePunkte] = useState([]);
    const [vermesseneLinien, setVermesseneLinien] = useState([]);

    const [mapCenterPosition, setMapCenterPosition] = useState({
        lat: 52.616163464359566,
        lng: 9.077535492746305,
    });

    const getNearbyVermessung_Punkte_CVA = () => {
        TiefbauService.getNearbyVermessung_Punkte_CVA(
            mapCenterPosition.lat,
            mapCenterPosition.lng,
            1000
        ).then(
            (response) => {
                console.log(response.data);


                //add all points that are not already included

                response.data.forEach((point) => {
                    if (!vermessenePunkte.some((p) => p.id === point.id)) {
                        vermessenePunkte.push(point);
                    }
                }
                );

             //   setVermessenePunkte(response.data);

            })
    }


    const getNearbyVermessung_Linien_CVA = () => {
        TiefbauService.getNearbyVermessung_Linien_CVA(
            mapCenterPosition.lat,
            mapCenterPosition.lng,
            1000
        ).then(
            (response) => {
                console.log(response.data);
                setVermesseneLinien(response.data);
            })

    }


    useEffect(() => {

        setTimeout(() => {
            getNearbyVermessung_Punkte_CVA();
            getNearbyVermessung_Linien_CVA();
        } , 2000);


    }
    , []);

    useEffect(() => {
        getNearbyVermessung_Punkte_CVA();
            getNearbyVermessung_Linien_CVA();

        }

        , [mapCenterPosition]);

    const createNewLine = () => {
        if (selectedPoints.length < 2) {
            Toastr.error("Es müssen mindestens 2 Punkte ausgewählt werden");
            return;
        }

        //nur die ids der punkte werden benötigt

        const payloadSelectedPoints = selectedPoints.map((point) => point.id);

        const payload = {
            selectedPoints: payloadSelectedPoints,
        }

        TiefbauService.createLineFromPoints(payload).then(
            (response) => {
                Toastr.success("Linie erstellt");
                setSelectedPoints([]);

                getNearbyVermessung_Linien_CVA();
            })

    }
    return (
<>
        <Row>

            <Col md={12}>

            </Col>



        </Row>
            <Row>
                <Col md={10}>
                    <MapComponent onPointSelect={handleAddPoint} setMapCenterPosition={setMapCenterPosition}>
                        {vermessenePunkte.map((point, index) => (
                            <VermessungPointMarker
                                key={index}
                                point={point}
                                index={index}
                                constructionTypes={constructionTypes}
                                positionInArray={selectedPoints.findIndex((p) => p.id === point.id)}
                                onPointSelect={handleAddPoint}
                                onPointRemove={handleRemovePoint}
                            />
                        ))}


                        {vermesseneLinien?.map((line, index) => (
                            <GeoJSON key={index} data={JSON.parse(line.geometry)} style={{color: (constructionTypes.find(x => x.name === line.construction_type)?.color || "pink")   }}/>

                        ))}

                    </MapComponent>
                </Col>
                <Col md={2}>
                    <Button color="success"
                            style={
                                {width: "100%"}

                            }

                            onClick={createNewLine}>Linie erstellen</Button>
                    <br/>
                    <br/>

                    <p>Gewählte Punkte</p>
                    <ListGroup>
                        {selectedPoints.map((point, index) => (
                            <ListGroupItem key={point.id}>
                <span className="float-start">
                  {point.id} - {point.construction_type}
                </span>
                                <span
                                    className="float-end text-danger"
                                    onClick={() => handleRemovePoint(point)}
                                    style={{ cursor: "pointer", marginLeft: "8px" }}
                                >
                  &times;
                </span>
                                {index > 0 && (
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className="float-end"
                                        style={{ cursor: "pointer", marginRight: "8px" }}
                                        onClick={() => handleMoveUp(index)}
                                    />
                                )}
                                {index < selectedPoints.length - 1 && (
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className="float-end"
                                        style={{ cursor: "pointer", marginRight: "8px" }}
                                        onClick={() => handleMoveDown(index)}
                                    />
                                )}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
</>
    );
};

export default PointToLine;
