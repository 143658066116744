import api from './api';
class NetManagementService {
    getNetDir(net) {
        return api.get('/netze/get/verzeichniss?identifier='+net);
    }
    getSubnetDirByID(id) {
        return api.get('/netze/subnetze/verzeichniss?id='+id);
    }
    getHostByID(id) {
        return api.get('/netze/subnetze/hosts/byId?id='+id);
    }
    getHostCreditsByID(id, cred_type) {
        return api.get('/netze/subnetze/host/creds?id='+id+"&cred_type="+cred_type);
    }
    toggleHostMonitoring(payload) {
        return api.post('/netze/subnetze/host/edit/toggleMonitoring', payload);
    }
    saveHost(payload) {
        return api.post('/netze/subnetze/host/edit/details', payload);
    }
    saveHostCredits(payload) {
        return api.post('/netze/subnetze/host/edit/creds', payload);
    }
    saveNetwork(payload) {
        return api.post('/netze/editNetz', payload);
    }
    reloadAlgalonHosts(){
      //  return api.get("https://algalon.cva.de/reloadDHosts")
    }
}
export default new NetManagementService();