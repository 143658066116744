import {Component, useEffect, useRef, useState} from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button, Table, Row, Col,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo, faPencilAlt, faPlus} from '@fortawesome/free-solid-svg-icons';

import BackupCheckService from "../../../services/backup_check.service";
import DragAndResizeableWindow from "../../DragAndResizeableWindow";
import Utils from "../../Utils";
import UserEdit from "../UserControl/UserEdit";
import moment from "moment";
import BackupReportAccountForm from "./BackupReportAccountForm";

const BackupReportAccountControl = (props) => {

    const [accountTable, setAccountTable] = useState([{}]);
    const [queryText, setQueryText] = useState("");

    const editAccount = (account) =>{
        const name = "Account ("+account.id+ " "+account.email+") bearbeiten";
        const userEditWindow =
                {
                    name,
                    component : <BackupReportAccountForm account={account} refreshAccountTable={refreshAccountTable} closeWindow={() => props.closeWindow(name)}/>,
                    open : true,
                    minimized : false,
                    width: 600,
                    minWidth: 500,
                    height: 600,
                    minHeight: 400,
                    x: 1,
                    y: 1
                }
        props.openWindow(userEditWindow);
    }


    const showAddDialog = () =>{
        const name = "Account hinzufügen";

        const template  = {
            activated : 1,
            type : "iperius",
            email : "",
            name : "",
            days_allowed : 3
        }
        const userAddWindow =
                {
                    name,
                    component : <BackupReportAccountForm account={template} refreshAccountTable={refreshAccountTable} closeWindow={() => props.closeWindow(name)}/>,
                    open : true,
                    minimized : false,
                    width: 600,
                    minWidth: 500,
                    height: 600,
                    minHeight: 400,
                    x: 1,
                    y: 1
                }
        props.openWindow(userAddWindow);
    }



    const refreshAccountTable = () =>{
        BackupCheckService.getAccountSummary(queryText).then((response) => {

            for(let ba of response.data) {

                var inform = moment(ba.date);
                var today = moment(new Date());
                ba.diff = today.diff(inform, "days") + 1;

                if (ba.diff > ba.days_allowed)
                    ba.color_class2 = "text-danger"
                else if (ba.diff >= ba.days_allowed)
                    ba.color_class2 = "text-warning"
                else
                    ba.color_class2 = "text-success"
                if (isNaN(ba.diff))
                    ba.diff = "nie";

                if (ba.result == 1)
                    ba.color_class = "text-success"
                else if (ba.result === 2)
                    ba.color_class = "text-warning"
                else if (ba.result === 3)
                    ba.color_class = "text-danger"
                else ba.color_class = "text-info"

            }


            setAccountTable(response.data)
        })
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            refreshAccountTable()
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [queryText])


    useEffect(() => {
        refreshAccountTable()

    }, [])
    return (
        <>
            <Row className={"mb-3 mx-auto"}>
                <Col xs="8">
                    <Input
                        type="queryText"
                        name="queryText"
                        id="queryText"

                        onChange={(e) => {
                            setQueryText(e.target.value)
                        }
                        }
                        placeholder="Suche nach..."
                    />

                </Col>

                <Col xs="2">

                </Col>

                <Col xs="2">
                    <Button variant="outline-dark"  color={"success"} size="sm" onClick={() => {

                        showAddDialog()
                    }} >
                        <FontAwesomeIcon className="pe-1 ps-1" icon={faPlus} />
                        Account hinzufügen
                    </Button>
                </Col>

            </Row>
        <Table className="table-dark table-striped table-hover table-borderless">
            <thead>
            <tr>
                <th className="bg-darker">#</th>
                <th className="bg-darker">Name</th>
                <th className="bg-darker">Typ</th>
                <th className="bg-darker">E-Mail</th>
                <th className="bg-darker">Letzte Meldung</th>
                <th className="bg-darker">Eingang</th>
                <th className="bg-darker">Letztes Ergebnis</th>
                <th className="bg-darker">Aktiviert</th>
                <th className="bg-darker">Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {
                accountTable.map((account, i) =>
                    <tr>
                        <th scope="row">{account.id}</th>
                        <td>{account.name}</td>
                        <td>{account.type}</td>
                        <td>{account.email}</td>
                        <td className={"text-center " + account.color_class2}>{account.diff === "nie" ? account.diff : "vor "+ account.diff+ ( account.diff === 1 ? " Tag" : " Tagen") }</td>
                        <td className={"text-center " + account.color_class2}>{Utils.formatDateNoHour(account.date)}</td>
                        <td className={"text-center " + account.color_class}>{Utils.formatBackupResult(account.result)}</td>
                        <td className={"text-center " + (account.activated === 1 ? "text-success" : "text-danger" )}>{account.activated === 1 ? "✔" : "✘"}</td>
                        <td>
                            <Button variant="outline-dark"  size="sm" onClick={() => {
                                editAccount(account)
                            }} >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
            </>
    )
};

export default BackupReportAccountControl;