import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Button, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import AuthService from "../../services/auth.service";

class NavBar extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  componentDidMount() {
     const user = AuthService.getCurrentUser();
  }

  maximizeWindow = (window) => {

    this.props.openWindow({
      name: "Webtool",
      component : <iframe id="if1" width="100%" height="254" style={{height: "100%", border: "none", overflow: "hidden"}}
                          src="https://webtool.na-intern.net"></iframe>,
      open: true
    })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen  // navbar collapse
    });
  }

  render() {
    return (
      <Navbar color="dark" dark className="navbar shadow-sm p-3 mb-5 rounded " expand="md">
        <Button color="dark" onClick={this.props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ms-auto" navbar>
            {this.props.openWindows && this.props.openWindows.filter(w => !w.open).map((window, i) =>
                <NavItem key={i}>
                  <NavLink href="#" onClick={() => {this.props.maximizeWindow(window)}}>{window.name}</NavLink>
                </NavItem>
            )
            }
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
