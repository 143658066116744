import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label, Tooltip} from "reactstrap";
import BillingService from "../../../services/billing.service";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import de from 'date-fns/locale/de';
import moment from "moment";
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";
import Holidays from "date-holidays";
import WorkDayEditFrame from "./WorkDayEditFrame";
import tokenService from "../../../services/token.service";
import TokenService from "../../../services/token.service";
import Utils from "../../Utils";
import NetManagementService from "../../../services/net_management.service";
import UserService from "../../../services/user.service";

registerLocale('de', de)


const DayOffManagementRow = (props) => {


    const toggleApproved = (day) => {

        const payload = {
            empID : day.user_id,
            day :    moment(props.day.day).add("2", "h").toDate(),
            approved : day.approved === 0 ? 1 : 0
        }
        UserService.toggleDayOffApproved(payload).then((response) => {
            props.refreshTable();
        })

    }

    return (
        <tr className={""}>
            <td>{Utils.formatDateNoHour(props.day?.day)}</td>
            <td>{Utils.formatDayOffType(props.day.selected_type)}</td>
            <td>{props.employees.find(x => x.id === props.day.user_id)?.firstName} {props.employees.find(x => x.id === props.day.user_id)?.lastName}</td>
            <td className={"text-center " + (props.day.approved === 1 ? "text-success" : "text-danger" )}><a href="#toggle" onClick={() => {toggleApproved(props.day)}}>{props.day.approved === 1 ? "✔" : "✘"}</a></td>

        </tr>

    )

};

export default DayOffManagementRow;