import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import Select from 'react-select';
import BackupCheckService from "../../../services/backup_check.service";


const BackupReportAccountForm = (props) => {

    const [account, setAccount] = useState(props.account);

    const possibleTypes = [
        { value: 'iperius', label: 'Iperius'},
        { value: 'trilead', label: 'Trilead'},
        { value: 'navicat', label: 'Navicat Premium' },
        { value: 'hlbackup', label: 'Hardlink Backup' },
        { value: 'qnap-sync', label: 'QNAP Sync' },
        { value: 'qnap-system', label: 'QNAP System' },
        { value: 'proxmox', label: 'Proxmox' },
    ]
    const [activated, changeActivated] = useState(account.activated === 1)
    const [type, changeType] = useState(possibleTypes.find(g => g.value == props.account.type))


    const nameRef = useRef();
    const daysRef = useRef();
    const emailRef = useRef();

    const saveAccount = () => {

        if(account.id){
            const payload = {
                id: account.id,
                name: nameRef.current.value,
                days_allowed : daysRef.current.value,
                email : emailRef.current.value,
                activated : activated,
                type: type.value
            }

            BackupCheckService.saveAccount(payload).then(r => {
                props.refreshAccountTable();
                props.closeWindow()

            })
        }
        else {
            const payload = {
                name: nameRef.current.value,
                days_allowed : daysRef.current.value,
                email : emailRef.current.value,
                activated : activated,
                type: type.value
            }

            BackupCheckService.addAccount(payload).then(r => {
                props.refreshAccountTable();
                props.closeWindow()

            })
        }

        /***
         * Todo: catch error
         */

    }

    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={8} className="pt-1 pb-1">
                    <Label for="firstName">Name:</Label>
                    <Input type="text" name="name" innerRef ={nameRef} defaultValue={account.name} id="name"/>
                </Col>
                <Col sm={4} className="pt-1 pb-1">
                    <Label for="lastName">Tage erlaubt:</Label>
                    <Input type="number" innerRef ={daysRef} defaultValue={account.days_allowed} name="days_allowed" id="days_allowed"/>

                </Col>
            </FormGroup>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0 mt-3">
                <Col sm={8} className="pt-1 pb-1">
                    <Label for="emailField">E-Mail:</Label>
                    <Input type="email" innerRef ={emailRef} defaultValue={account.email} name="email" id="email" />
                </Col>
                <Col sm={4} className="pt-1 pb-1">
                    <Label for="groups">Typ:</Label>
                    <Select
                        defaultValue={type}
                        name="groups"
                        options={possibleTypes}
                        className="basic-multi-select"
                        onChange={(el) => changeType(el)}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        classNamePrefix="select"
                    />
                </Col>
                <Col sm={6} className="pt-1 pb-1 mt-3">
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" className="text-white" innerRef checked={activated} onChange={() => changeActivated(!activated) }
                            />{' '}
                            Aktiviert
                        </Label>
                    </FormGroup>
                </Col>

                <Col sm={6} className="pt-1 pb-1 mt-2">
                </Col>

            </FormGroup>
            <Button color="success" className="mt-2 float-end position-absolute" onClick={saveAccount} style={{bottom: 50, right: 10}}>{account.id ? "Speichern" : "Hinzufügen"}</Button>

        </Form>
    )
};

export default BackupReportAccountForm;