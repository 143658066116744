import api from "./api";
import TokenService from "./token.service";
class AuthService {
    login(username, password) {
        return api
            .post("/auth/login", {
                username,
                password
            })
            .then(response => {
                console.log(response)
                if (response.data.accessToken) {
                    TokenService.setUserData(response.data);
                    location.reload();

                }
                return response.data;
            });
    }
    logout() {
        TokenService.removeUser();
    }
    register(username, email, password) {
        return api.post("/auth/signup", {
            username,
            email,
            password
        });
    }
    getCurrentUser() {
        return TokenService.getUser();
    }
}
export default new AuthService();