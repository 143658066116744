import moment from 'moment'

const formatDate =  (value) =>{
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY HH:mm");
    }
}

const formatDateNoHour =  (value) =>{
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
    }
}

const formatBackupResult =  (value) =>{
    if (value) {
        switch (value){
            case 1:
                return "Erfolg";
            case 2:
                return "Warnung";
            case 3:
                return "Fehler";
            case 88:
                return "Unbestimmt";

            default:
                return "Keine Rückgabe";
        }    }
}

const formatDayOffType =  (value) =>{
    if (value) {
        switch (value){
            case "vacation":
                return "Urlaub";
            case "vacation-half-e":
                return "Urlaub Nachtmittags";
            case "vacation-half-s":
                return "Urlaub Vormittags";
            case "sickness":
                return "Krankheit";
            case "homeoffice":
                return "Home Office";
            default:
                return "Unbestimmt";
        }    }
}

const Utils = {
    formatDate,
    formatDateNoHour,
    formatDayOffType,
    formatBackupResult
}
export default Utils;