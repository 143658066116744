import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/sidebar/SideBar';
import Content from './components/content/Content';
import LoginForm from "./components/content/LoginForm";
import AuthService from "./services/auth.service";
import UserControl from "./components/windows/UserControl/UserControl";
import BackupReportAccountControl from "./components/windows/BackupChecks/BackupReportAccountControl";
import BackupReportInbox from "./components/windows/BackupChecks/BackupReportInbox";
import NetSummary from "./components/windows/NetworkManagement/NetSummary";
import WorkUnitManagement from "./components/windows/BillingManagement/WorkUnitManagement";
const indexContext = React.createContext(100);
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ProxmoxMachinesManagement from "./components/windows/ProxmoxManagement/ProxmoxMachinesManagement";
import WorkDayManagement from "./components/windows/EmployeeManagement/WorkDayManagement";
import WorkDayRequests from "./components/windows/EmployeeManagement/WorkDayRequests";
import PointToLine from "./components/windows/GISManagement/PointToLine";

import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41], // Ankerpunkt des Markers anpassen
  popupAnchor: [0, -41], // Ankerpunkt des Popups anpassen
});

L.Marker.prototype.options.icon = DefaultIcon;
class App extends Component {

  constructor(props) {
    super(props);


    // Moblie first
    this.state = {
      isOpen: false,
      isMobile: true,
      isLoggedIn: false,
      trigger: false,
      openWindows : [
        {
          name : "BBNA",
          component : <iframe id="if1" width="100%" height="254" style={{height: "100%", border: "none", overflow: "hidden"}}
                              src="http://bbna.enet.de/bb/"></iframe>,
          open : true,
          minimized : false,
          x: 1500,
          y: 1
        },
        {
          name : "Testphase",
          component : <PointToLine  openWindow={this.openWindow} closeWindow={this.closeWindow} />,
          open : true,
          minimized : false,
          width: 1550,
          minWidth: 600,
          height: 800,
          minHeight: 550,
          x: 250,
          y: 1
        },
      ]
    }

    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth
    const widthLimit = 576;
    const isMobile = (width <= widthLimit);
    const wasMobile = (this.previousWidth <= widthLimit);

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile
      })
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));

    const interval = setInterval(() => {
    }, 1000);
    //return () => clearInterval(interval);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  trigger = () => {
    this.setState({ trigger: !this.state.trigger });
  }


  openWindow = (window) => {
console.log(window)

    var index = this.state.openWindows.findIndex(x=> x.name === window.name);
    if (index > -1) {
      this.setState({
        openWindows: [
          ...this.state.openWindows.slice(0, index),
          Object.assign({}, this.state.openWindows[index], {open: true}),
          ...this.state.openWindows.slice(index + 1)
        ]
      });
    }
    else
     this.setState({ openWindows: [...this.state.openWindows, window] })

    console.log(this.state.openWindows)

  }
  closeWindow = (name) => {
    console.log("close name")
    console.log(name)
    var index = this.state.openWindows.findIndex(x=> x.name === name);
    if (index > -1)
      this.setState({
        openWindows: [
          ...this.state.openWindows.slice(0,index),
          ...this.state.openWindows.slice(index+1)
        ]
      });

    /**
     * Entfernt das Component/Object aus dem Array
     */
  }

  minimizeWindow = (name) => {
    var index = this.state.openWindows.findIndex(x=> x.name === name);
    if (index > -1)
      this.setState({
        openWindows: [
          ...this.state.openWindows.slice(0,index),
          Object.assign({}, this.state.openWindows[index], {open: false}),
          ...this.state.openWindows.slice(index+1)
        ]
      });
  }



  maximizeWindow = (window) => {
    var index = this.state.openWindows.findIndex(x=> x.name === window.name);
    if (index > -1)
      this.setState({
        openWindows: [
          ...this.state.openWindows.slice(0,index),
          Object.assign({}, this.state.openWindows[index], {open: true}),
          ...this.state.openWindows.slice(index+1)
        ]
      });
  }




  render() {
    return (
      <div className="App wrapper bg-dark text-white">
        <ToastContainer />


        {
          AuthService.getCurrentUser() ?
          <>

            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} trigger={this.trigger} openWindow={this.openWindow} closeWindow={this.closeWindow} minimizeWindow={this.minimizeWindow} openWindows={this.state.openWindows}/>
              <Content toggle={this.toggle} isOpen={this.state.isOpen} openWindow={this.openWindow}  maximizeWindow={this.maximizeWindow} closeWindow={this.closeWindow} minimizeWindow={this.minimizeWindow} openWindows={this.state.openWindows} />

          </>
            :

            <LoginForm />
        }



      </div >
    );
  }
}

export default App;
