import React, {useRef, useState} from "react";
import L from "leaflet";
import { Marker, Popup, Tooltip  , useMap } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from 'react-dom/server';

const VermessungPointMarker = ({ point, index, onPointSelect, onPointRemove, onPointOrder, positionInArray, constructionTypes }) => {
    const [status, setStatus] = useState("OFF");
    const [position, setPosition] = useState([point.lat, point.lng]);
    const [name, setName] = useState(point.name);
    const [id, setId] = useState(point.id);

    const map = useMap();
    const popupRef = useRef(null);

    const handleRightClick = () => {
     /*   if (popupRef.current) {
            popupRef.current.setLatLng(position);
            popupRef.current.openOn(map);
        }*/

        onPointRemove(point);
    };

    const handleLeftClick = (e) => {
        popupRef.current.close();

        onPointSelect(point);

    };

    // Convert FontAwesome Icon to Leaflet Icon
    const faIcon = new L.DivIcon({
        className: 'dummy', // Specify something to get rid of the default class.

        html: ReactDOMServer.renderToString(<FontAwesomeIcon icon={faInfoCircle} size="lg" color={
            positionInArray != -1 ? 'orange' :
            constructionTypes.find((constructionType) => constructionType.name === point.construction_type)?.color || 'pink'}


                                                             style={{ width: '16px', height: '16px', backgroundColor: 'black', borderRadius: '50%' }}
        />),
        iconSize: [16, 16], // Größe der SVG-Grafik anpassen
        iconAnchor: [8, 8], // Ankerpunkt der SVG-Grafik anpassen
    });

    return (
        <Marker
            position={position}
            icon={faIcon}

            //rotate marker
            eventHandlers={{
                contextmenu: () => handleRightClick(),
                click: () => handleLeftClick(),
            }}
        >
            {
                positionInArray != -1 && <Tooltip direction="right" offset={[10, 0]} opacity={1} permanent>
                    id: {point.id},{'\n'}
                    pos: {positionInArray}
                </Tooltip>
            }


            <Popup ref={popupRef}>
                <div>
                    <h3>{name}</h3>
                    <p>ID: {id}</p>
                    {/* FontAwesome Icon converted to Leaflet Icon */}
                    <FontAwesomeIcon icon={faInfoCircle} size="lg" color="blue" />
                </div>
            </Popup>
        </Marker>
    );
};

export default VermessungPointMarker;
