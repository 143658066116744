import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import Select from 'react-select';
import NetDetail from "./NetDetail";
import NetManagementService from "../../../services/net_management.service";


const HostEdit = (props) => {
    const [hostCreditsData, setHostCreditsData] = useState([{}]);

    const usernameRef = useRef();
    const passwordRef = useRef();



    const saveHostCredits = () =>{
        const payload = {
            host_id : props.id,
            cred_type : props.type,
            username : usernameRef.current.value,
            password : passwordRef.current.value,
        }

        NetManagementService.saveHostCredits(payload).then((response) => {
            if(props.refresh) {
                props.refresh();
            }
           /* if(props.closeWindow)
                props.closeWindow()*/
            NetManagementService.reloadAlgalonHosts().then(r => console.log("Algalon Hosts neu geladen."))

            /**
             * Todo: Fix auto close after saving
             */
        })
    }
    const loadHostCreditsData = () =>{
        NetManagementService.getHostCreditsByID(props.id, props.type).then((response) => {
            if(response.data[0] && (response.data[0].username || response.data[0].password))
                setHostCreditsData(response.data[0])
            else
                setHostCreditsData({username : "", password : ""})
        })
    }
    useEffect(() => {
        loadHostCreditsData()
    }, [])
    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="firstName">Benutzername:</Label>
                    <Input type="text" innerRef={usernameRef} defaultValue={hostCreditsData.username} name="username" id="username"/>
                </Col>
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="lastName">Passwort:</Label>
                    <Input type="text" innerRef={passwordRef} defaultValue={hostCreditsData.password} name="password" id="password"/>
                </Col>
            </FormGroup>

            <Button color="success" onClick={() => {saveHostCredits(hostCreditsData)}} className="mt-2 float-end">Speichern</Button>

        </Form>
    )
};

export default HostEdit;