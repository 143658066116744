import api from './api';
class TiefbauService {
    getNearbyVermessung_Punkte_CVA(lat, lng, radius) {
        return api.get('/tiefbau/nearby/Vermessung_Punkte_CVA',

            {
                params: {
                    lat: lat,
                    lng: lng,
                    radius: radius
                }
            });
    }


    getNearbyVermessung_Linien_CVA(lat, lng, radius) {
        return api.get('/tiefbau/nearby/Vermessung_Linien_CVA',

            {
                params: {
                    lat: lat,
                    lng: lng,
                    radius: radius
                }
            });
    }

    createLineFromPoints(selectedPoints) {
        return api.post('/tiefbau/vermessung/createLineFromPoints', selectedPoints);
    }

}

export default new TiefbauService();