import api from './api';
class UserService {
    getUserSummary() {
        return api.get('/users/get/summary');
    }
    getUserByID(id) {
        return api.get('/users/get/byID?userid='+id);
    }
    editUser(user) {
        return api.post('/users/edit/details', user);
    }
    editWorkDayValue(payload){
        return api.post('/users/edit/workDayValue', payload);
    }
    toggleDayOffApproved(payload){
        return api.post('/users/edit/workDayApproved', payload);
    }
    getDaysOff(){
        return api.get('/users/get/daysOff');
    }
}
export default new UserService();