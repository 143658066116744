import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import Select from 'react-select';
import moment from "moment";
import BillingService from "../../../services/billing.service";
import UserService from "../../../services/user.service";


const UserEdit = (props) => {
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();


    const editUser = () => {

        const payload = {
            user : {
                id : props.user.id,
                firstName : firstNameRef.current.value,
                lastName : lastNameRef.current.value,
                email : emailRef.current.value,
            },
            selectedGroups
        }
        console.log()
        UserService.editUser(payload).then((response) => {
                      /* if(props.closeWindow)
                props.closeWindow()*/
        })
    }
    const [user, setUser] = useState(props.user);

    const handleGroupChange = (selectedOptions) => {
        setSelectedGroups(selectedOptions);
    }

    const [selectedGroups, setSelectedGroups] = useState([  { value: 'administrator', label: 'Administrator' }])
    const userGroups = [
        { value: 'administrator', label: 'Administrator', color: '#FF8B00' },
        { value: 'developer', label: 'Entwickler', color: '#FF8B00' },
        { value: 'user', label: 'Benutzer', color: '#FF8B00' },
        { value: 'na_emp', label: 'NA Mitarbeiter', color: '#FF8B00' },
        { value: 'cva_emp', label: 'CVA Mitarbeiter', color: '#FF8B00' },
        { value: 'billing', label: 'Buchhaltung', color: '#FF8B00' },
    ]

    useEffect(() => {
        user.selectedGroups = [  { value: 'administrator', label: 'Administrator', color: '#FF8B00' }]
        setSelectedGroups( [  { value: 'developer', label: 'Administrator', color: '#FF8B00' }])

        UserService.getUserByID(props.user.id).then(response => {
            const userData = response.data;
            userData.groups = JSON.parse(userData.groups)
            userData.selectedGroups = [  ]

            for(const group of userData.groups){
                userData.selectedGroups.push(  { value: group, label: userGroups.find(x => x.value === group).label, color: '#FF8B00' });
            }
            console.log(userData)
            console.log(userData.selectedGroups)
            setSelectedGroups( userData.selectedGroups)
            console.log("selectedGroups")
            console.log(selectedGroups)

            setUser(userData)
        })

    }, [])
    return (
        <Form inline>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="firstName">Vorname:</Label>
                    <Input type="text" name="firstName" innerRef={firstNameRef} defaultValue={user.firstName} id="firstName"/>
                </Col>
                <Col sm={6} className="pt-1 pb-1">
                    <Label for="lastName">Nachname:</Label>
                    <Input type="text" innerRef={lastNameRef} defaultValue={user.lastName} name="lastName" id="lastName"/>

                </Col>
            </FormGroup>
            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                <Col sm={8} className="pt-1 pb-1">
                    <Label for="emailField">E-Mail:</Label>
                    <Input type="email" innerRef={emailRef} defaultValue={user.email} name="email" id="email" />
                </Col>
                <Col sm={12} className="pt-1 pb-1">
                    <Label for="groups">Gruppen:</Label>
                    <Select
                        defaultValue={selectedGroups}
                        value={selectedGroups}
                        isMulti
                        name="groups"
                        options={userGroups}
                        className="basic-multi-select"
                        onChange={handleGroupChange}

                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'grey',
                                primary: 'grey',
                                neutral0: '#121212',
                            },
                        })}
                        classNamePrefix="select"
                    />

                </Col>
            </FormGroup>
            <Button color="success" className="mt-2 float-end" onClick={editUser}>Speichern</Button>
        </Form>
    )
};

export default UserEdit;